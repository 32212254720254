import React from 'react'
import PropTypes from 'prop-types'
import nightModeIllu from '../../assets/illustrations/night-mode-illu.svg'
import Slider from '../misc/Slider'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n";
import {isItuOrPegasus} from "wipi-common/src/lib/util";
import {useI18nContext} from "../i18n/I18nContext";
import Text from "../i18n/Text"
import './ServiceCenterBanner.scss'

export default function ServiceCenterBanner({info}) {
    const context = useI18nContext()
    if (info.isInBusinessTime !== false || isItuOrPegasus()) {
        return null
    }
    return <Slider duration={200}>
        <div className="text-center mt-5">
            <p>
                <img src={nightModeIllu}
                     alt={getI18nTextWithContext(context, 'startVideo.serviceCenter.illuAlt')}
                     data-test-id="dfbee671-6cd1" />
            </p>
            <div className={"sc-closed"} data-test-id="edd6f542-72d8"><Text k="startVideo.serviceCenter.isClosed" /></div>
            <p data-test-id="4768ac3a-9383">{info.businessTime}</p>
        </div>
    </Slider>
}

ServiceCenterBanner.propTypes = {
    isInBusinessTime: PropTypes.bool,
    businessTime: PropTypes.string
}