import React from 'react'
import PropTypes from 'prop-types'
import BackButton from './BackButton'

export default function BackToMethodSelectionButton({caseInfo, isMobileDevice}) {
    if (hasOnlyOneMethod(caseInfo)) {
        return null
    }
    return <BackButton to="/" isMobileDevice={isMobileDevice} />
}

BackToMethodSelectionButton.propTypes = {
    caseInfo: PropTypes.shape({
        modules: PropTypes.shape({
            identMethodSelection: PropTypes.shape({
                methodChoice: PropTypes.array.isRequired
            }).isRequired
        }).isRequired
    }).isRequired
}

function hasOnlyOneMethod(caseInfo) {
    return caseInfo
        && caseInfo.modules
        && caseInfo.modules.identMethodSelection
        && caseInfo.modules.identMethodSelection.methodChoice
        && caseInfo.modules.identMethodSelection.methodChoice.length < 2
}
