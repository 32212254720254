import React from 'react'
import PropTypes from 'prop-types'
import {CopyableCaseId} from './CopyableCaseId'
import Text from "../i18n/Text";

const APP_DEEPLINK_BASE = process.env.REACT_APP_APP_DEEPLINK_BASE

export default function ContinueInApp({caseId}) {
    return <>
        <div className="text-center">
            <strong data-test-id="68584dd0-e143">
                <Text k="common.caseId" />:<br />
                <CopyableCaseId caseId={caseId} />
            </strong>
        </div>
        <p>
            <a className="mt-4 btn btn-primary btn-block"
               href={APP_DEEPLINK_BASE + caseId}
               target="_blank"
               rel="noopener noreferrer"
               data-test-id="4f62fefe-e6b4">
                <Text k="common.continueInApp" />
            </a>
        </p>
    </>
}

ContinueInApp.propTypes = {
    caseId: PropTypes.string.isRequired
}