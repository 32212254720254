import React from 'react'
import PropTypes from 'prop-types'

export default function Select({props, choices}) {
    return <select {...props} onChange={e => props.onChange(e.target.value)}>
        <option></option>
        { choices.map(choice => <option value={choice.key} key={choice.key}>{choice.display}</option>) }
    </select>
}

Select.propTypes = {
    props: PropTypes.object.isRequired,
    choices: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired
        })
    ).isRequired
}