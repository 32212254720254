import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getBrowserLanguage, getLanguageByCode} from "wipi-common/src/lib/i18n";
import {LOG_EVENTS} from "wipi-common/src/lib/logging/events";
import LOG from "wipi-common/src/lib/logging/logger";

export class ListElement extends Component {

    chooseLanguage = (e) => {
        e.stopPropagation()
        let language
        if (e.target.tagName.toUpperCase() === "LI") {
            language = getLanguageByCode(e.target.dataset.language)
        } else {
            language = getLanguageByCode(e.target.parentElement.dataset.language)
        }
        const context = {
            "deviceLanguage": getBrowserLanguage(),
            "currentLanguage": this.props.selectedLanguage,
            "selectedLanguage": language.value,
            "wasLanguageChanged": true
        }
        LOG.logInfo(LOG_EVENTS.CE_LANGUAGE_SELECTION, undefined, undefined, context)
        this.props.changeLanguage(e, language)
    }

    render() {
        let className = ""
        if (this.props.index !== this.props.languageCount - 1) {
            className = "border-bottom"
        }
        return <li className={className} onClick={this.chooseLanguage} data-language={this.props.element.value}>
            <p>{this.props.element.title}</p>
            {this.props.element.value === this.props.selectedLanguage ?
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <path fill="#333"
                          d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0zm5.065 5.337L8.594 13.94a.403.403 0 0 1-.341.192.41.41 0 0 1-.288-.122l-3.968-3.934a.452.452 0 0 1-.013-.62l.918-1.004a.437.437 0 0 1 .624-.018l2.362 2.29 4.237-6.651a.435.435 0 0 1 .608-.131l1.202.787c.2.132.26.405.13.608z"/>
                </svg>
                : ""}
        </li>
    }
}

ListElement.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    languageCount: PropTypes.number.isRequired,
    element: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }).isRequired
}

export default ListElement