import React from 'react'
import PropTypes from 'prop-types'
import BackButton from '../misc/BackButton'
import './StartBasic.scss'
import basicDownload from '../../assets/illustrations/basic-download.svg'
import Text from "../i18n/Text"

export default function StartBasicAfterDownloadCoupon({isMobileDevice, backToBranchUrl, continueToBranchBtn}) {
    return <>
        <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                <p className="text-center mt-3">
                    <img src={basicDownload} alt="Download" className="img-fluid" width="140" data-test-id="2ddca144-68d2" />
                </p>
                <p className="text-center mt-5 mb-5" data-test-id="22cc2c6b-5e89">
                    <strong><Text k="startBasic.branchFinderContent" /></strong>
                </p>
            </div>
        </div>
        <hr/>
        <section className="btn-section">
            <BackButton isMobileDevice={isMobileDevice} to="/basic"/>
            {backToBranchUrl && continueToBranchBtn &&
            <button className="pi-btn pi-btn--primary" onClick={() => {
                window.location.replace(backToBranchUrl)
            }}>
                {continueToBranchBtn}
            </button>
            }
        </section>
    </>
}

StartBasicAfterDownloadCoupon.propTypes = {
    isMobileDevice: PropTypes.bool.isRequired,
    backToBranchUrl: PropTypes.string,
    continueToBranchBtn: PropTypes.string
}
