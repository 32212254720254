import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import BackButton from '../misc/BackButton'
import InstallAppBanner from '../misc/InstallAppBanner'
import appShowcaseBasic from '../../assets/illustrations/basic-onboarding.svg'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import LOG from "wipi-common/src/lib/logging/logger";

export default function StartBasicContinueInApp({isMobileDevice, caseId}) {

    useEffect(() => {
        LOG.logInfo(LOG_EVENTS.BA_APP_INFO)
    }, [])

    return <>
        <div className="row">
            <div className="col-lg-12">
                <InstallAppBanner caseId={caseId}
                                  isMobileDevice={isMobileDevice}
                                  showcaseImg={appShowcaseBasic} />
            </div>
        </div>
        <hr />
        <BackButton isMobileDevice={isMobileDevice} to="/basic" />
    </>
}

StartBasicContinueInApp.propTypes = {
    isMobileDevice: PropTypes.bool.isRequired,
    caseId: PropTypes.string.isRequired
}
