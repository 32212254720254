import React from 'react'
import PropTypes from 'prop-types'
import FooterLink from './FooterLink'

export default function DynamicLinks({legalInfo}) {
    if (!legalInfo) {
        return null
    }
    let links = []

    if (legalInfo.dataPrivacy) {
        links.push(<FooterLink key={legalInfo.dataPrivacy.id}
                               target={legalInfo.dataPrivacy.target}
                               text={legalInfo.dataPrivacy.text}
                               dataTestId="c7c623ca-e36e" />)
    }
    if (legalInfo.dataPrivacySupplement) {
        links.push(<FooterLink key={legalInfo.dataPrivacySupplement.id}
                               target={legalInfo.dataPrivacySupplement.target}
                               text={legalInfo.dataPrivacySupplement.text}
                               dataTestId="95c96ff8-e59c" />)
    }
    return links
}

DynamicLinks.propTypes = {
    legalInfo: PropTypes.shape({
        dataPrivacy: PropTypes.shape({
            text: PropTypes.string.isRequired,
            target: PropTypes.string.isRequired
        }),
        dataPrivacySupplement: PropTypes.shape({
            text: PropTypes.string.isRequired,
            target: PropTypes.string.isRequired
        }),
    })
}