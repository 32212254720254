import React, {useState} from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom"
import MethodSelection from '../method-selection/MethodSelection'
import ScrollToTop from '../misc/ScrollToTop'
import StartVideo from '../start-video/StartVideo'
import StartBasic from '../start-basic/StartBasic'
import StartEId from '../start-eid/StartEId'
import StartAutoId from '../start-autoid/StartAutoId'
import UnexpectedError from '../start-eid/unexpected-error/UnexpectedError'
import IdentSuccess from '../start-eid/ident-success/IdentSuccess'
import PropTypes from "prop-types";
import StartPhoto from "../start-photo/StartPhoto";

const ROUTER_BASE_PATH = process.env.PUBLIC_URL
const IS_MOBILE_DEVICE = /ipad|iphone|android/.test(navigator.userAgent.toLowerCase()) && !window.MSStream

export default function CaseInfo({caseInfo,queryParams}) {
    const [browserInfo, setBrowserInfo] = useState({isMobileDevice: IS_MOBILE_DEVICE, checkedVideo: false})
    const [couponData, setCouponData] = useState()

    return <BrowserRouter basename={ROUTER_BASE_PATH}>
        <ScrollToTop>
            <Routes>
                <Route path="basic/*" element={
                    <StartBasic
                      caseInfo={caseInfo}
                      isMobileDevice={browserInfo.isMobileDevice}
                      couponData={couponData}
                      setCouponData={setCouponData} />
                } />
                <Route path="photo/*" element={
                    <StartPhoto
                      caseInfo={caseInfo} />
                } />
                {["npa/*", "eid/*"].map(path => <Route key={path} path={path} element={
                    <StartEId
                      caseInfo={caseInfo}
                      isMobileDevice={browserInfo.isMobileDevice} />
                } />)}
                <Route path="autoid/*" element={
                    <StartAutoId
                      caseInfo={caseInfo}
                      isMobileDevice={browserInfo.isMobileDevice} />
                }/>
                <Route path="eIdError/*" element={
                    <UnexpectedError
                      caseInfo={caseInfo}
                      caseId={caseInfo.caseId}
                      isMobileDevice={browserInfo.isMobileDevice} />
                } />
                <Route path="eIdSuccess/*" element={
                    <IdentSuccess caseId={caseInfo.caseId} />
                } />
                <Route path="video/*" element={
                    <StartVideo
                      caseInfo={caseInfo}
                      browserInfo={browserInfo}
                      setBrowserInfo={setBrowserInfo} />
                } />
                <Route path="*" element={
                    <MethodSelection
                      caseInfo={caseInfo}
                      search={queryParams} />
                } />
            </Routes>
        </ScrollToTop>
    </BrowserRouter>
}

CaseInfo.propTypes = {
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired
    }).isRequired,
    queryParams:PropTypes.string
}
