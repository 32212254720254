import React from 'react'
import hinIcon from '../../assets/icons/hint.svg'
import Text from "../i18n/Text";

export default function CaseConflictInfo() {
    return <div className="text-center">
        <img src={hinIcon} alt="" className="img-fluid m-4 mw-100"  data-test-id="0df48425-8be9" />
        <h4 data-test-id="2ed6b9dc-1496"><Text k='case.used.reference.id.title'/></h4>
        <p data-test-id="2654a2e1-f332">
            <Text k="case.used.reference.id.desc" />
        </p>
    </div>
}