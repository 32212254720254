import React from 'react'
import PropTypes from 'prop-types'
import './BottomTooltip.scss'

export function BottomTooltip({hide, content}) {
    return <div className={hide ? 'fade' : ''} placement="bottom">
        <div className="tooltip show bs-tooltip-bottom" x-placement="bottom">
            <div className="tooltip-inner" role="tooltip" aria-hidden="false">
                {content}
            </div>
            <span className="arrow"></span>
        </div>
    </div>
}

BottomTooltip.propTypes = {
    content: PropTypes.string.isRequired,
    hide: PropTypes.bool
}
