import React from 'react'
import PropTypes from 'prop-types'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n"
import I18nContext from "./I18nContext"


export default function Text({k}) {
    return <I18nContext.Consumer>{contextConsumerFn(k)}</I18nContext.Consumer>
}


Text.propTypes = {
    key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
}

function contextConsumerFn(key) {
    return (context) => {
        return getI18nTextWithContext(context, key, '')
    }
}