import React from 'react'
import Text from "../i18n/Text"

export default function RateLimitExceeded() {
    return <div className="text-center">
        <h4>
            <Text k="unexpectedError.rateLimitExceeded" />
        </h4>
        <p>
            <Text k="unexpectedError.rateLimitExceededAction" />
        </p>
    </div>
}