import React from 'react'
import technicalProblem from '../../assets/illustrations/technical-problem.png'

export default function CaseCancelledInfo({message}) {
    return <div className="text-center">
        <img src={technicalProblem} alt="Technical problem" className="mt-5 mb-5" data-test-id="0df48425-8be8" />
        <p data-test-id="2654a2e1-f331">{message}</p>
    </div>
}




