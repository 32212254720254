import React from 'react'
import PropTypes from 'prop-types'

export default function PrincipalDisplayLogo({src, alt}) {
    if (src && src !== 'none') {
        return <img src={src} alt={alt} height="56" data-test-id="14c54604-f8cb"/>
    } else if (alt) {
        return alt
    } else {
        return null
    }
}

PrincipalDisplayLogo.propTypes = {
    src: PropTypes.string
}