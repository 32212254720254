import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import copyIcon from '../../assets/icons/copy.svg'
import './CopyableCaseId.scss'
import {BottomTooltip} from './BottomTooltip'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n";
import {useI18nContext} from "../i18n/I18nContext";

export function CopyableCaseId({caseId}) {
    const i18nContext = useI18nContext()
    const [isCopied, setCopied] = useState(false)
    const timerRef = useRef(null)
    useEffect(() => () => clearTimeout(timerRef.current))

    const copy = (e) => {
        e.target.select()
        e.target.setSelectionRange(0, 99999)
        document.execCommand("copy")
        setCopied(true)
        setTimeout(setCopied, 3000, false)
    }

    return <div className="inner-addon right-addon">
        <img src={copyIcon} alt="" className="icon-addon" />
        <input type="text"
               className="btn btn-light cursor-pointer"
               value={caseId}
               readOnly
               onClick={copy}
               data-test-id="d2dd9cf9-7a6g"
               title={getI18nTextWithContext(i18nContext, 'common.copyToClipboard')} />
        <BottomTooltip hide={!isCopied} content={getI18nTextWithContext(i18nContext, 'common.copied')} />
    </div>
}

CopyableCaseId.propTypes = {
    caseId: PropTypes.string.isRequired
}
