import React, {useEffect, useState} from 'react'
import Slider from '../misc/Slider'
import {showInHeadlessMode} from "wipi-common/src/lib/util";
import {useI18nContext} from "../i18n/I18nContext";
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n";

export default function FAQCard({cardId, header, body}) {
    const context=useI18nContext()
    const [collapsed, setCollapsed] = useState(true)
    const toggleCollapsed = () => setCollapsed(!collapsed)
    const faqCardId = 'faq-card-' + cardId
    const collapseId = 'faq-collapse-' + cardId

    useEffect(() => {
        showInHeadlessMode()
    })

    return <div className="card">
        <div className="card-header" id={faqCardId}>
            <h3
                role="link"
                className="h6 py-2 mb-0"
                style={{ cursor: 'pointer' }}
                onClick={toggleCollapsed}
                aria-expanded={!collapsed}
                aria-controls={collapseId}
                data-test-id={'db5e8543-872a-' + cardId}>
                <strong data-test-id={'c00af86f-87d7-' + cardId}>{getI18nTextWithContext(context,header)}</strong>
            </h3>
        </div>
        <Slider duration={300} closed={collapsed}>
            <div className="collapse show" aria-labelledby={faqCardId} id={collapseId} >
                <div
                    dangerouslySetInnerHTML={{ __html: getI18nTextWithContext(context,body) }}
                    className="card-body"
                    data-test-id={'3e134f7d-84fa-' + cardId}/>
            </div>
        </Slider>
    </div>
}
