import 'core-js/features/url-search-params';

const VALID_CASE_PARAMS = ['caseId', 'caseid', 'v', 'vn', 'code', 'vorgangsnummer']
const ACCOUNTING_NUMBER_PARAM = 'an'
const REFERENCE_ID_PARAM = 'rn'
const AN_SEARCH_REGEX = new RegExp('&?' + ACCOUNTING_NUMBER_PARAM + '=[\\w]*', 'i')
const RN_SEARCH_REGEX = new RegExp('&?' + REFERENCE_ID_PARAM + '=[\\w]*', 'i')

export function removeEasyConnectParams(search) {
    if (!search) {
        return ''
    }
    search = search.replace(AN_SEARCH_REGEX, '')
        .replace(RN_SEARCH_REGEX, '')
        .replace('?&', '?')
    return search === '?' ? '' : search
}

export function addCaseIdParam(search, caseId) {
    const param = `${VALID_CASE_PARAMS[0]}=${caseId}`
    if (!search || search.length === 1) {
        return '?' + param
    }
    return search + '&' + param
}

export function parseCaseParams() {
    const caseId = parseCaseIdFromUrl()
    if (caseId) {
        return {caseId}
    }

    const easyConnectParams = parseEasyConnectParametersFromUrl()
    if (easyConnectParams.accountingNumber) {
        return easyConnectParams
    }

    throw new Error('Could not parse case info')
}

export function parseCaseIdFromUrl() {
    if (!window.location.search) {
        return null
    }

    let caseIdQuery
    try {
        const parsedQuery = window.location.search.substr(1).split('&')
        caseIdQuery = parsedQuery
            .map(search => search.split('='))
            .find(pair => VALID_CASE_PARAMS.indexOf(pair[0]) > -1)
    } catch (error) {
    }

    if (!caseIdQuery || caseIdQuery.length !== 2) {
        return null
    }
    return caseIdQuery[1]
}

export function getParameterValue(paramName) {
    let results = new RegExp('[\\?&]' + paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]') + '=([^&#]*)').exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getParameterByName(paramName) {
    const params = new URLSearchParams(window.location.search)
    return params.get(paramName)
}

export function updateQueryParameter(paramName, newValue) {
    const params = new URLSearchParams(location.search);
    params.set(paramName, newValue);
    window.history.replaceState({}, '', `${location.pathname}?${params}`);
}

function parseEasyConnectParametersFromUrl() {
    if (!window.location.search) {
        return {}
    }

    let accountingNumber, referenceId
    try {
        const parsedQuery = window.location.search.substr(1).split('&')
        parsedQuery
            .map(search => search.split('='))
            .forEach(pair => {
                if (pair[0] === ACCOUNTING_NUMBER_PARAM) {
                    accountingNumber = pair[1]
                } else {
                    if (pair[0] === REFERENCE_ID_PARAM) {
                        referenceId = pair[1]
                    }
                }
            })
    } catch (error) {
    }

    return {accountingNumber, referenceId}
}

export function sanitizeMessage(logEvents) {
    if (logEvents && logEvents instanceof Array) {
        logEvents.forEach(event => {
            if (localStorage.getItem("sessionId") && (!event.sessionId || event.sessionId === '')) {
                event.sessionId = localStorage.getItem("sessionId")
            }
            if (event && event.hasOwnProperty("message") && event.message.hasOwnProperty("message")) event.message.message = getString(event.message.message)
        })
    }
    return logEvents
}

let getString = function (object) {
    if (object) {
        if (typeof object == 'string') {
            return object;
        } else {
            try {
                return JSON.stringify(object);
            } catch (e) {
            }
        }
    }
    return null;
};

export function isPathInUrl(urls) {
    const href = window.location.href.split('?')[0]
    let values = []
    let result = false
    if (urls) {
        if (!Array.isArray(urls))
            values.push(urls)
        else
            values = urls

        values.forEach(url => {
            if (href.endsWith(url))
                result = true
        })
    }
    return result
}

export function hasPropertyValue(obj, key, value) {
    return obj.hasOwnProperty(key) && obj[key] === value
}

export function isJsonContentType(obj) {
    return hasPropertyValue(obj, 'content-type', 'application/json')
}


export function extendedValidateStatus(status, include) {
    return (include && Array.isArray(include) && include.includes(status)) || (status >= 200 && status < 300)
}

export function showInHeadlessMode() {
    if (getParameterValue('headless')) {
        let headlessElements = document.getElementsByClassName('headless');
        for (let i = 0; i < headlessElements.length; i++) {
            headlessElements[i].style.display = 'none';
        }
    }
}

export function isItuOrPegasus() {
    return process.env.REACT_APP_STAGE === 'ITU' || process.env.REACT_APP_STAGE === 'PEGASUS'
}

export function addClass(e, c) {
    let cn = e.className
    if (cn.indexOf(c) !== -1) {
        return
    }
    if (cn !== '') {
        c = ' ' + c
    }
    e.className = cn + c
}

export function removeClass(e, c) {
    e.className = e.className.replace(new RegExp("s?\\b" + c + "\\b", "g"), '')
}

export function isClientError(error) {
    return error && error.response && error.response.status && (error.response.status >= 400 && error.response.status <= 499)
}

export function isServerError(error) {
    return error && error.response && error.response.status && (error.response.status >= 500 && error.response.status <= 599)
}

export function isError503(error) {
    return error && error.response && error.response.status && error.response.status ===503
}