import React from 'react'

export default function WhitelabelStyle({primaryColour, secondaryColour}) {
    if (!primaryColour || !secondaryColour) {
        return null
    }

    const style = `.pi-btn--primary {
                        color: ${secondaryColour};
                        background: ${primaryColour};
                    }
                    .pi-card__header {
                        background-color: ${primaryColour} !important;
                        background-image: none !important;
                        border: none !important;
                    }
                    .pi-card__header__title {
                        color: ${secondaryColour} !important;
                    }
                    progress::-webkit-progress-value {
                        background: ${primaryColour} !important; }
                    progress::-moz-progress-bar {
                        background: ${primaryColour} !important; }
                    .pi-meter-wrapper .pi-meter::-moz-meter-bar {
                        background-color: ${primaryColour} !important;}
                    .pi-meter-wrapper .pi-meter::-webkit-meter-optimum-value {
                        background-color: ${primaryColour} !important;}
                    .pi-spinner__circle {
                        stroke: ${primaryColour};}
                    input:enabled:focus,
                    input[type='text']:focus,
                    input[type='email']:focus,
                    input[type='number']:focus,
                    input[type='password']:focus,
                    input[type='search']:focus,
                    input[type='tel']:focus,
                    input[type='text']:focus,
                    input[type='url']:focus,
                    textarea:enabled:focus,
                    select:enabled:focus {
                        border-color: ${primaryColour} !important; }`

    return <style>{style}</style>
}