import React from 'react'
import AnimateHeight from 'react-animate-height';
import PropTypes from "prop-types"

export default function Slider({duration, closed, children}) {
    return (
        <AnimateHeight
            duration={duration || 500}
            height={closed ? 0 : "auto"}
        >
            {children}
        </AnimateHeight>
    )
}

Slider.propTypes = {
    duration: PropTypes.number,
    closed: PropTypes.bool,
    children: PropTypes.node
}
