import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Method from './Method'
import Title from '../misc/Title'
import {Navigate} from 'react-router-dom'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import ShortCaseInfo from '../misc/ShortCaseInfo'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n"
import Text from "../i18n/Text"
import LOG from "wipi-common/src/lib/logging/logger"
import {useI18nContext} from "../i18n/I18nContext"
import {patchUserMethodSelection} from "../../lib/emmiApi";

const METHOD_SELECTION_DOCUMENT_TITLE = 'POSTIDENT | '

export default function MethodSelection(props) {
    const context = useI18nContext()
    const [isCalled, setCalled] = useState(false)

    useEffect(() => {
        document.title = METHOD_SELECTION_DOCUMENT_TITLE + getI18nTextWithContext(context, 'methodSelection.title')
        LOG.logInfo(LOG_EVENTS.CE_METHOD_SELECTION)
    }, [context])

    const clickCallback = useCallback((method, enabled) => {
        if (enabled) {
            setCalled(true)
            patchUserMethodSelection(props.caseInfo.caseId, method)
        }
    }, [props.caseInfo.caseId])

    return <>
        <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <Title heading={<Text k="methodSelection.heading"/>}
                       subHeading=""
                       testId="3275a09d-5fab"/>
                {mapIdentMethodSelection(props)}
            </div>
        </div>
        <ShortCaseInfo bcName={props.caseInfo.principalDisplayName}/>
    </>

    function mapIdentMethodSelection({caseInfo, search}) {
        if (!caseInfo || !caseInfo.modules || !caseInfo.modules.identMethodSelection || !caseInfo.modules.identMethodSelection.methodChoice) {
            return null
        }
        const choices = caseInfo.modules.identMethodSelection.methodChoice
        if (choices.length === 1) {
            return <Navigate to={{pathname: '/' + choices[0].method, search}}/>
        }

        return choices.map(choice => {
            return <Method
                key={choice.method}
                onClickCallback={clickCallback}
                method={choice.method}
                queryParams={search}
                heading={<Text k={`methodSelection.${choice.method}.heading`}/>}
                subHeading={<Text k={`methodSelection.${choice.method}.subHeading`}/>}
                info={(choice.method === 'autoid' || choice.method === 'eid') && choice.footer}
                category={choice.method === 'video' && caseInfo.callcenterCategory}
                enabled={!isCalled && choice.enabled}
                disabledInfo={choice.disabledInfo}
            />
        })
    }
}

MethodSelection.propTypes = {
    caseInfo: PropTypes.shape({
        modules: PropTypes.shape({
            identMethodSelection: PropTypes.shape({
                methodChoice: PropTypes.arrayOf(
                    PropTypes.shape({
                        method: PropTypes.string.isRequired,
                        continueButton: PropTypes.shape({
                            target: PropTypes.string.isRequired
                        })
                    })
                ).isRequired
            })
        }).isRequired
    }).isRequired,
    category:PropTypes.string,
    caseId: PropTypes.string,
    search: PropTypes.string.isRequired
}
