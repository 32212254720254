import React from 'react'

import InstallAppBanner from '../../misc/InstallAppBanner'

import './UnexpectedError.scss'
import BackToMethodSelectionButton from '../../misc/BackToMethodSelectionButton'
import FAQ from '../../faq/FAQ'
import {useI18nContext} from "../../i18n/I18nContext"
import {getI18nTextWithParentKey} from "wipi-common/src/lib/i18n";
import Text from "../../i18n/Text"
import {getEidShowcaseByLanguage} from "wipi-common/src/components/LanguageImageSort";

export default function UnexpectedError({caseInfo, caseId, isMobileDevice}) {
    const context = useI18nContext()

    return (
        <div>
            <div className="pi-section pi-section--center">
                <div className="pi-section__content">
                    <h4><Text k='unexpectedError.error'/></h4>
                    <p className="pi-paragraph--no-max-width" data-test-id="2654a2e1-f331">
                        <Text k="unexpectedError.tryAgain"/>
                    </p>
                </div>
            </div>
            <InstallAppBanner caseId={caseId}
                              isMobileDevice={isMobileDevice}
                              showcaseImg={getEidShowcaseByLanguage(context.lang)}/>
            <hr/>
            <BackToMethodSelectionButton caseInfo={caseInfo} isMobileDevice={isMobileDevice}/>
            <FAQ faq={getI18nTextWithParentKey(context, 'startEId.faq')}/>
        </div>
    )
}
