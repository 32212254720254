import React from 'react'
import PropTypes from 'prop-types'
import './ShortCaseInfo.scss'
import Text from "../i18n/Text";

export default function ShortCaseInfo({bcName}) {
    return <div className="row short-case-info">
        <div className="col-lg-12">
            <hr />
            <p className="text-center">
                <Text k="common.yourIdent" />
                <br/>
                {bcName}
            </p>
            <hr />
        </div>
    </div>
}

ShortCaseInfo.propTypes = {
    bcName: PropTypes.string.isRequired
}