import React, {useEffect, useState} from 'react'
import {Route, Routes} from "react-router-dom"
import PropTypes from 'prop-types'
import FAQ from '../faq/FAQ'
import Title from '../misc/Title'
import StartBasicControls from './StartBasicControls'
import StartBasicContinueInApp from './StartBasicContinueInApp'
import StartBasicMailConfirmed from './StartBasicMailConfirmed'
import ShortCaseInfo from '../misc/ShortCaseInfo'
import {createBasicIdent} from '../../lib/emmiApi'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import StartBasicAfterDownloadCoupon from "./StartBasicAfterDownloadCoupon"
import {getI18nTextWithContext, getI18nTextWithParentKey} from "wipi-common/src/lib/i18n"
import Text from "../i18n/Text"
import LOG from "wipi-common/src/lib/logging/logger"
import {useI18nContext} from "../i18n/I18nContext"

export default function StartBasic({caseInfo, isMobileDevice, couponData, setCouponData}) {
    const context = useI18nContext()
    const [backToBranchUrl, setBackToBranchUrl] = useState("");
    const [successIdent, setSuccessIdent] = useState(false);
    const [continueToBranchBtn, setContinueToBranchBtn] = useState("");

    useEffect(() => {
        document.title = 'POSTIDENT | '+getI18nTextWithContext(context, 'startBasic.title')
        setSuccessIdent(false);
        createBasicIdent(caseInfo.caseId)
            .then((basicIdent) => {
                setBackToBranchUrl(basicIdent.backToBranchUrl);
                setSuccessIdent(true);
                setContinueToBranchBtn(basicIdent.continueToBranchBtn);
            })
            .catch(() => {
                setBackToBranchUrl(null);
            });
        LOG.logInfo(LOG_EVENTS.PD_START_BASIC)
    }, [caseInfo,context])

    return <div className="col-lg-12">
        <div className="headless">
            <Title heading={<Text k="startBasic.heading"/>}
                   subHeading={<Text k="startBasic.subHeading"/>}
                   testId="32cba09d-512b"/>
            <Routes>
                <Route path="app" element={
                    <StartBasicContinueInApp
                        isMobileDevice={isMobileDevice}
                        caseId={caseInfo.caseId} />
                } />
                <Route path="mail-confirmed" element={
                    <StartBasicMailConfirmed
                        isMobileDevice={isMobileDevice}
                        backToBranchUrl={backToBranchUrl}
                        continueToBranchBtn={continueToBranchBtn} />
                } />
                <Route path="after-coupon-download" element={
                    <StartBasicAfterDownloadCoupon
                        isMobileDevice={isMobileDevice}
                        backToBranchUrl={backToBranchUrl}
                        continueToBranchBtn={continueToBranchBtn} />
                } />
                <Route path="*" element={
                    <StartBasicControls
                        caseInfo={caseInfo}
                        successIdent={successIdent}
                        backToBranchUrl={backToBranchUrl}
                        isMobileDevice={isMobileDevice}
                        couponData={couponData}
                        setCouponData={setCouponData} />
                } />
            </Routes>
            <ShortCaseInfo caseId={caseInfo.caseId} bcName={caseInfo.principalDisplayName}/>
        </div>
        <FAQ faq={getI18nTextWithParentKey(context, 'startBasic.faq')}/>
    </div>
}

StartBasic.propTypes = {
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired
    }).isRequired,
    isMobileDevice: PropTypes.bool.isRequired,
    couponData: PropTypes.object,
    setCouponData: PropTypes.func.isRequired
}
