import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import FAQ from '../faq/FAQ'
import {doBrowserCheck, getServiceCenterInfo} from '../../lib/emmiApi'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import ServiceCenterBanner from './ServiceCenterBanner'
import ContinueInBrowser from './ContinueInBrowser'
import BackToMethodSelectionButton from '../misc/BackToMethodSelectionButton'
import Title from '../misc/Title'
import InstallAppBanner from '../misc/InstallAppBanner'
import ShortCaseInfo from '../misc/ShortCaseInfo'
import {getI18nTextWithContext, getI18nTextWithParentKey} from "wipi-common/src/lib/i18n"
import LOG from "wipi-common/src/lib/logging/logger"
import {useI18nContext} from "../i18n/I18nContext"
import Text from "../i18n/Text"
import FaceDetectionHint from "./FaceDetectionHint";
import {getVideoShowcaseByLanguage} from "wipi-common/src/components/LanguageImageSort";


export default function StartVideo({caseInfo, browserInfo, setBrowserInfo}) {
    const context = useI18nContext()
    const [serviceCenterInfo, setServiceCenterInfo] = useState({})

    useEffect(() => {
        document.title = 'POSTIDENT | '+getI18nTextWithContext(context, 'startVideo.title')
        LOG.logInfo(LOG_EVENTS.IH_START_VIDEO)
    }, [context])

    useEffect(() => {
        if (!browserInfo.checkedVideo) {
            doBrowserCheck('video', caseInfo.caseId)
                .catch(() => ({ isBrowserSupported: false, isMobileDevice: browserInfo.isMobileDevice }))
                .then(({isBrowserSupported, isMobileDevice}) => {
                    const mergedBrowserInfo = Object.assign({}, browserInfo, {
                        isVideoSupported: isBrowserSupported,
                        checkedVideo: true,
                        isMobileDevice
                    })
                    setBrowserInfo(mergedBrowserInfo)
                })
        }
    }, [setBrowserInfo, caseInfo.caseId, browserInfo, browserInfo.checkedVideo])

    useEffect(() => {
        getServiceCenterInfo(caseInfo.callcenterCategory)
            .then(setServiceCenterInfo)
            .catch()
    }, [caseInfo.callcenterCategory,context])

    return <div className="col-md-12">
        <div className="headless">
            <Title heading={<Text k="startVideo.heading" />}
                   testId="32cba09d-5fab" />
            <InstallAppBanner heading={<Text k="startVideo.useApp" />}
                              showcaseImg={getVideoShowcaseByLanguage(context.lang)}
                              caseId={caseInfo.caseId}
                              isMobileDevice={browserInfo.isMobileDevice} />
            <FaceDetectionHint caseInfo={caseInfo}/>
            <ServiceCenterBanner info={serviceCenterInfo}/>
            <ContinueInBrowser isBrowserSupported={browserInfo.isVideoSupported}
                               serviceCenterInBusiness={serviceCenterInfo.isInBusinessTime}
                               caseInfo={caseInfo} />
            <hr />
            <BackToMethodSelectionButton caseInfo={caseInfo} isMobileDevice={browserInfo.isMobileDevice} />
            <ShortCaseInfo bcName={caseInfo.principalDisplayName} />
        </div>
        <FAQ faq={getI18nTextWithParentKey(context, 'startVideo.faq')} />
    </div>
}

StartVideo.propTypes = {
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired
    }).isRequired,
    browserInfo: PropTypes.shape({
        isMobileDevice: PropTypes.bool.isRequired,
        checkedVideo: PropTypes.bool.isRequired,
        isVideoSupported: PropTypes.bool
    }).isRequired
}