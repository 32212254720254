import axios from 'axios'
import rateLimit from 'axios-rate-limit'
import {isClientError, isError503, sanitizeMessage} from "./util"
import {LOG_EVENTS} from "./logging/events"
import LOG from "./logging/logger"

const MAX_POST_LOG_ATTEMPTS = 4
const AUTHORIZATION_B64 = process.env.REACT_APP_AUTHORIZATION_B64

const defaultOptions = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'PI-Platform': process.env.REACT_APP_EMMI_PI_PLATFORM,
        'PI-App-Version': process.env.REACT_APP_EMMI_PI_APP_VERSION,
        'PI-Interface-Key': process.env.REACT_APP_EMMI_PI_INTERFACE_KEY,
        'Authorization': AUTHORIZATION_B64 ? 'Basic ' + AUTHORIZATION_B64 : undefined,
        'Cache-Control': 'no-store, no-cache',
        'Expires': 0,
        'Pragma': 'no-cache',
    },
    baseURL: generateEmmiBasePath(),
    timeout: process.env.REACT_APP_EMMI_TIMEOUT
}

const instance = axios.create(defaultOptions)

const http = rateLimit(instance, {maxRPS: parseInt(process.env.REACT_APP_MAX_RPS)})

instance.interceptors.request.use(config => {
    let language = 'de'
    const storeLanguage = localStorage.getItem("language")
    if (storeLanguage)
        language = storeLanguage
    config.headers['Accept-Language'] = language
    return config;
}, error => Promise.reject(error))

instance.interceptors.response.use(response => response, error => {
    if (!error) {
        return Promise.reject(error)
    }
    const url = error.config && error.config.url
    if (url && url.endsWith('reports/wipi/logs')) { // ignore log requests to prevent loop
        return Promise.reject(error)
    }

    const logMessage = parseErrorResponse(error)
    if (isClientError(error) || isError503(error)) {
        LOG.logInfo(LOG_EVENTS.REQUEST_ERROR, logMessage)
    } else {
        LOG.logError(LOG_EVENTS.REQUEST_ERROR, logMessage)
    }
    return Promise.reject(error)
})

export function getServerTime() {
    return instance.request({url: '/time'})
        .then(response => {
            return response.data
        })
        .catch(error => {
            throw new Error('server time retrieval failed')
        })
}

export function postLog(logEvents, count = 1) {
    logEvents = sanitizeMessage(logEvents);
    const config = {
        url: '/reports/wipi/logs',
        method: 'POST',
        data: logEvents
    }

    return instance.request(config).catch(error => {
        if (count < MAX_POST_LOG_ATTEMPTS) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    postLog(logEvents, count + 1).then(resolve).catch(reject)
                }, count * 2000) // retry after 2, 4, 8 seconds...
            })
        }
        throw error
    })
}

function parseErrorResponse(error) {
    const url = error.config && error.config.url
    const code = error.code
    const message = error.message
    let status = undefined
    let data = undefined
    let emmiV2Version = undefined
    if (error.response) {
        status = error.response.status
        emmiV2Version = error.response.headers && error.response.headers['emmi-version']
        data = JSON.stringify(error.response.data)
        if (data) {
            data = data.substring(0, 150)
        }
    }
    return `${status || code}|${emmiV2Version}|${url}|${data || message}`
}

function generateEmmiBasePath() {
    if(process.env.REACT_APP_IS_OFFICIAL_EMMI === 'true') {
        return window.location.origin + "/emmi-postident/api"
    } else {
        return process.env.REACT_APP_EMMI_BASE_PATH || process.env.REACT_APP_EMMI_BASE
    }
}

export default http