import React, {useState, useCallback} from 'react'
import {sendCouponEmail} from '../../lib/emmiApi'
import {Navigate} from "react-router-dom"
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import LOG from "wipi-common/src/lib/logging/logger";
import Text from "../i18n/Text";
import UnexpectedError from "../misc/UnexpectedError";

export default function SendCouponEmailButton({caseId, search}) {
    const [isEmailTriggered, setEmailTriggered] = useState(false)
    const [toMailConfirmed, setToMailConfirmed] = useState(false)
    const [isErrorSendingEmail, setErrorSendingEmail] = useState(false)

    const sendEmail = useCallback(() => {
        setEmailTriggered(true)
        LOG.logInfo(LOG_EVENTS.BA_COUPON_EMAIL)
        sendCouponEmail(caseId)
            .then(() => setToMailConfirmed(true))
            .catch(() => {
                setEmailTriggered(false);
                setErrorSendingEmail(true);
            })
    }, [caseId])

    if (toMailConfirmed) {
        return <Navigate to={{pathname: "/basic/mail-confirmed", search}} />
    }
    if(isErrorSendingEmail) {
        return <UnexpectedError/>
    }
    return <button className="btn btn-outline-secondary btn-block"
            data-test-id="e32efe4d-1576"
            onClick={sendEmail}
            disabled={isEmailTriggered}>
        <Text k="startBasic.sendCouponBtn"/>
    </button>
}
