import React from 'react'
import PropTypes from 'prop-types'

export default function Title({heading, subHeading, testId}) {
    return <div className="text-center mb-4">
        <h2 className="h4" data-test-id={testId + '-head'}>{heading}</h2>
        { subHeading && <p data-test-id={testId + '-sub'}>{subHeading}</p> }
    </div>
}

Title.propTypes = {
    heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    subHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    testId: PropTypes.string.isRequired
}