import React from 'react'

export default function Spinner({circleStroke}) {
    return (
        <div className="pi-spinner__header">
            <div className="pi-spinner__icon">
                <SpinnerSvg circleStroke={circleStroke} />
            </div>
        </div>
    )
}

export function SpinnerSvg({circleStroke = '#FFCC00'}) {
    return <svg width='100px' height='100px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
         className="uil-ring-alt" data-test-id="c6347ffa-8ddc">
        <rect x="0" y="0" width="100" height="100" fill="none" className="bk"></rect>
        <circle cx="50" cy="50" r="40" stroke="none" fill="none" strokeWidth="10" strokeLinecap="round"></circle>
        <circle cx="50" cy="50" r="40" stroke={circleStroke} fill="none" strokeWidth="6" strokeLinecap="round"
                className="pi-spinner__circle">
            <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="0" to="502"></animate>
            <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
        </circle>
    </svg>
}