import {useEffect} from 'react'
import {
    NavigationType,
    useLocation,
    useNavigationType,
} from "react-router-dom"

/**
 * When mounted, scrolls to the window top on every history push, e.g. when clicking links to switch routes
 *
 * @param children components to render
 * @param pathname URL path to watch
 * @returns the children components
 */
const ScrollToTop = ({ children }) => {
    const location = useLocation()
    const navigationType = useNavigationType()

    useEffect(() => {
        if (navigationType === NavigationType.Push) {
            smoothScrollToTop()
        }
    }, [location.pathname, navigationType])

    return children
};

export function smoothScrollToTop() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
}

export default ScrollToTop;