import React from 'react'
import PropTypes from 'prop-types'
import {EMAIL_REGEX} from './regex'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n";
import {useI18nContext} from "../i18n/I18nContext";

export default function EmailInput(props) {
    const context = useI18nContext()
    return <input type="email" {...props} onChange={getEmailOnChange(props.onChange, context)} />
}

EmailInput.propTypes = {
    onChange: PropTypes.func.isRequired
}

function getEmailOnChange(onChange, context) {
    return e => {
        let errorText
        if (e.target.value && e.target.value.endsWith('@epost.de')) {
            errorText = getI18nTextWithContext(context, 'dataCompletion.validation.email-epost')
        } else if (!e.target.value || e.target.value.match(EMAIL_REGEX)) {
            errorText = null
        } else {
            errorText = getI18nTextWithContext(context, 'dataCompletion.validation.email')
        }
        onChange(e.target.value, errorText)
    }
}