import React from 'react'
import PropTypes from 'prop-types'
import {isItuOrPegasus} from "wipi-common/src/lib/util"
import {useI18nContext} from "../i18n/I18nContext"
import Text from "../i18n/Text"

export default function ContinueInBrowser({isBrowserSupported, serviceCenterInBusiness, caseInfo}) {
    const context = useI18nContext()
    if (!isBrowserSupported || (!serviceCenterInBusiness && !isItuOrPegasus())) {
        return null
    }

    return <>
        <p className="text-center text-small mb-5" data-test-id="05880657-0c78">
            <Text k="startVideo.browser.preLink" />
            <a href={'/user/videochat/?v=' + caseInfo.caseId+((context)?'&lang='+context.lang:'')}>
                <Text k="startVideo.browser.link" />
            </a>
        </p>
    </>
}

ContinueInBrowser.propTypes = {
    isBrowserSupported: PropTypes.bool,
    serviceCenterInBusiness: PropTypes.bool,
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired,
        branding: PropTypes.string,
        legalNote: PropTypes.object
    }).isRequired
}
