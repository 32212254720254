import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import FAQ from '../faq/FAQ'
import {getI18nTextWithContext, getI18nTextWithParentKey} from "wipi-common/src/lib/i18n";
import Title from './AutoIdTitle'
import InstallAppBanner from '../misc/InstallAppBanner'
import ShortCaseInfo from '../misc/ShortCaseInfo'
import BackToMethodSelectionButton from '../misc/BackToMethodSelectionButton'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import './StartAutoId.scss'
import Text from "../i18n/Text";
import LOG from "wipi-common/src/lib/logging/logger"
import {useI18nContext} from "../i18n/I18nContext"
import {getAutoIdShowcaseByLanguage, getAvailabilityClockByLanguage} from "wipi-common/src/components/LanguageImageSort";

const START_AUTO_DOCUMENT_TITLE = 'POSTIDENT | '

export default function StartAutoId({caseInfo, isMobileDevice}) {
    const context = useI18nContext()

    useEffect(() => {
        const title = getI18nTextWithContext(context, 'startAutoId.title')
        document.title = START_AUTO_DOCUMENT_TITLE + title
        LOG.logInfo(LOG_EVENTS.PD_START_AUTOID)
    }, [context])

    return <div className="col-lg-12" id="autoid-page">
        <div className="headless">
            <div className="row margin-left">
                <Title heading={<Text k="startAutoId.heading"/>}
                       subHeading={<Text k="startAutoId.subHeading"/>}
                       info={getI18nTextWithContext(context, 'startAutoId.info')}
                       testId="jbsd723t-gp94"/>
                <div className="col-lg-4 clock-wrapper">
                    <img className="clock" src={getAvailabilityClockByLanguage(context.lang)}
                         alt={getI18nTextWithContext(context, 'startAutoId.accessibility')}/>
                </div>
            </div>
            <InstallAppBanner heading=" "
                              showcaseImg={getAutoIdShowcaseByLanguage(context.lang)}
                              caseId={caseInfo.caseId}
                              isMobileDevice={isMobileDevice}
                              method="autoid"
            />
            <hr/>
            <BackToMethodSelectionButton caseInfo={caseInfo} isMobileDevice={isMobileDevice}/>
            <ShortCaseInfo caseId={caseInfo.caseId} bcName={caseInfo.principalDisplayName}/>
        </div>
        <FAQ faq={getI18nTextWithParentKey(context, 'startAutoId.faq')}/>
    </div>
}

StartAutoId.propTypes = {
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired
    }).isRequired,
    isMobileDevice: PropTypes.bool.isRequired
}