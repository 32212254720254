import React from 'react'
import toastWithJam from '../../assets/illustrations/illu-toast.svg'
import Text from "../i18n/Text";

export default function UnexpectedError() {
    return <div className="text-center">
        <img src={toastWithJam} alt="Marmeladenbrot" className="mt-5 mb-5" data-test-id="0df48425-8be8" />
        <h4 data-test-id="2ed6b9dc-1495">
            <Text k="unexpectedError.error" />
        </h4>
        <p data-test-id="2654a2e1-f331">
            <Text k="unexpectedError.tryAgain" />
        </p>
    </div>
}