import React from 'react'
import PropTypes from 'prop-types'

export default function BusinessTimeInfo({info}) {
    if (!info) {
        return null
    }
    return <p className="m-0">
        <small data-test-id="e99c2f72-e780">{info}</small>
    </p>
}

BusinessTimeInfo.propTypes = {
    info: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ])
}