import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import chevron from '../../assets/icons/accordion_chevron.png'
import AdditionalInfo from './BusinessTimeInfo'
import basicIcon from '../../assets/icons/pi_basic.svg'
import videoIcon from '../../assets/icons/pi_video.svg'
import photoIcon from '../../assets/icons/pi_photo.png'
import eidIcon from '../../assets/icons/pi_eid.svg'
import autoIdIcon from '../../assets/icons/pi_autoid.svg'
import './Method.scss'
import {getServiceCenterInfo} from "../../lib/emmiApi";

const LOGOS = {
    basic: basicIcon,
    video: videoIcon,
    photo: photoIcon,
    eid: eidIcon,
    autoid: autoIdIcon
}

export function CustomLink(props) {
    const {enabled, ...rest} = props;
    return enabled ? <Link {...rest}>{props.children}</Link> : <div {...rest}>{props.children}</div>;
}

export function Method({
                           method,
                           heading,
                           subHeading,
                           queryParams,
                           info,
                           category,
                           enabled,
                           disabledInfo,
                           onClickCallback
                       }) {
    const [serviceCenterInfo, setServiceCenterInfo] = useState({})
    useEffect(() => {
        if (category) {
            getServiceCenterInfo(category)
                .then(setServiceCenterInfo)
                .catch()
        }
    }, [category])

    return <div className="dpag-method" onClick={() => {
        onClickCallback(method, enabled)
    }}>
        <CustomLink enabled={enabled} to={{pathname: '/' + method, search: queryParams}}
                    method={method} className="card p-4 mb-3" data-test-id={'38b62df6-b5d5-' + method}>
            <div className="media">
                <img src={LOGOS[method]} alt="Icon" className="align-self-center mr-4"
                     data-test-id={'59290177-1ff6-' + method}/>
                <div className="media-body align-self-center">
                    <h5 className="m-0" data-test-id={'9711df18-403e-' + method}>{heading}</h5>
                    <small className="text-muted" data-test-id={'14c87da7-526c-' + method}>{subHeading}</small>
                    <p className="m-0">
                        <small data-test-id="bf766297-4454">{info}</small>
                    </p>
                    <AdditionalInfo info={disabledInfo}/>
                    <AdditionalInfo info={serviceCenterInfo.businessTime}/>
                </div>
                {enabled ?
                    <img src={chevron} alt=">" className="align-self-center ml-2 chevron"
                         data-test-id={'a8ab44fc-7327-' + method}/>
                    : React.Fragment
                }
            </div>
        </CustomLink>
    </div>
}

Method.propTypes = {
    method: PropTypes.string.isRequired,
    onClickCallback: PropTypes.func.isRequired,
    heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    subHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    queryParams: PropTypes.string.isRequired,
    category: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    enabled: PropTypes.bool,
    disabledInfo: PropTypes.string
}

export default Method
