import React from 'react'
import PropTypes from 'prop-types'
import basicOnboardingIllu from '../../assets/illustrations/basic-onboarding.svg'
import {Link, useLocation} from "react-router-dom"
import BackToMethodSelectionButton from '../misc/BackToMethodSelectionButton'
import SendCouponEmailButton from './SendCouponEmailButton'
import DownloadCouponButton from './DownloadCouponButton'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n"
import Text from "../i18n/Text"
import {useI18nContext} from "../i18n/I18nContext"

export default function StartBasicControls({caseInfo,successIdent, backToBranchUrl, isMobileDevice, couponData, setCouponData}) {
    const context = useI18nContext()
    const location = useLocation()

    return <>
        <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                <p className="text-center">
                    <img src={basicOnboardingIllu} className="img-fluid mb-4" alt={getI18nTextWithContext(context, 'startBasic.heading')}
                         data-test-id="e24310d0-6d3b"/>
                </p>
                <p className="text-center">
                    <DownloadCouponButton caseId={caseInfo.caseId} hasIdent={successIdent} couponData={couponData} setCouponData={setCouponData}
                                          backToBranchUrl={backToBranchUrl} search={location.search}/>
                </p>
                <p className="text-center">
                    <SendCouponEmailButton caseId={caseInfo.caseId} search={location.search}/>
                </p>
                <p className="text-center">
                    <Link to={{pathname: "/basic/app", search: location.search}}
                          className="btn btn-outline-secondary btn-block"
                          data-test-id="8ca15ef1-658b">
                        <Text k="startBasic.openAppCouponBtn"/>
                    </Link>
                </p>
            </div>
        </div>
        <hr/>
        <BackToMethodSelectionButton caseInfo={caseInfo} isMobileDevice={isMobileDevice}/>
    </>
}

StartBasicControls.propTypes = {
    caseInfo: PropTypes.object.isRequired,
    isMobileDevice: PropTypes.bool.isRequired,
    couponData: PropTypes.object,
    setCouponData: PropTypes.func.isRequired
}
