export const LOG_LEVELS = {
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR'
}

export const EVENT_TYPES = {
    USER_ACTION: 'user.action',
    PAGE_VIEW: 'page.view',
    CHECK_RESULT: 'check.result',
    AGENT_MESSAGE: 'agent.message',
    WEBRTC: 'webrtc',
    MISC: 'misc'
}

export const LOG_EVENTS = {
    CE_CASE_CHECK: {
        eventId: 'ce.case.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CE_CASE_ERROR: {
        eventId: 'ce.case.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.WARN
    },
    CE_METHOD_SELECTION: {
        eventId: 'ce.method.selection',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CE_SELECT_METHOD: {
        eventId: 'ce.select.method',
        eventType: EVENT_TYPES.PAGE_VIEW
    },
    CE_VIDEO_METHOD: {
        eventId: "ce.select.video",
        eventType: EVENT_TYPES.PAGE_VIEW
    },
    CE_LANGUAGE_SELECTION: {
        eventId: 'ce.language.selection',
        eventType: EVENT_TYPES.MISC,
    },
    DC_CONTACT_DATA: {
        eventId: 'dc.contact.data',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    IH_START_VIDEO: {
        eventId: 'ih.start-video',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_CAM_CHECK: {
        eventId: 'mr.cam.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_CAM_ERROR: {
        eventId: 'mr.cam.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_CAM_SUCCESS: {
        eventId: 'mr.cam.success',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_CAM_PERMISSION: {
        eventId: 'mr.cam.permission',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_CAM_PERMISSION_ERROR: {
        eventId: 'mr.cam.permission.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_SPEAKER_CHECK: {
        eventId: 'mr.speaker.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_SPEAKER_ERROR: {
        eventId: 'mr.speaker.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_SPEAKER_AUDIO_CHECK: {
        eventId: 'mr.speaker.audio.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_SPEAKER_AUDIO_SUCCESS: {
        eventId: 'mr.speaker.audio.success',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_SPEAKER_AUDIO_ERROR: {
        eventId: 'mr.speaker.audio.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_MIC_PERMISSION: {
        eventId: 'mr.mic.permission',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_MIC_PERMISSION_ERROR: {
        eventId: 'mr.mic.permission.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_MIC_CHECK: {
        eventId: 'mr.mic.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_MIC_ERROR: {
        eventId: 'mr.mic.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_MIC_SIGNAL_CHECK: {
        eventId: 'mr.mic.signal.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    MR_MIC_SIGNAL_ERROR: {
        eventId: 'mr.mic.signal.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    CR_OCR_HELP: {
        eventId: 'cr.ocr.help',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_PHOTO: {
        eventId: 'cr.ocr.photo',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_CONFIRM: {
        eventId: 'cr.ocr.confirm',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_CHECK: {
        eventId: 'cr.ocr.check',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_SUCCESS: {
        eventId: 'cr.ocr.success',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_UNSUPPORTED: {
        eventId: 'cr.ocr.unsupported',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_EXPIRED: {
        eventId: 'cr.ocr.expired',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_OCR_GENERAL_ERROR: {
        eventId: 'cr.ocr.general.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_USER_SELF_ASSESSMENT: {
        eventId: 'cr.user.self.assessment',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CR_PRERECORD_PHOTO: {
        eventId: 'cr.prerecord.photo',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    VC_CONFIRM_CHAT: {
        eventId: 'vc.confirm.chat',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    IH_SERVICECENTER_CLOSED: {
        eventId: 'ih.servicecenter.closed',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.WARN
    },
    IH_SERVICECENTER_BUSY: {
        eventId: 'ih.servicecenter.busy',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    VC_WEBSOCKET_FALLBACK: {
        eventId: 'vc.websocket.fallback',
        eventType: EVENT_TYPES.MISC,
    },
    VC_WAIT: {
        eventId: 'vc.wait',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    VC_TEXT_CHAT: {
        eventId: 'vc.text.chat',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    VC_STEP_CONSENT: {
        eventId: 'vc.step.consent',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    DR_STEP_PHOTO_FRONT: {
        eventId: 'dr.step.photo.front',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    DR_STEP_PHOTO_BACK: {
        eventId: 'dr.step.photo.back',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    DR_STEP_PHOTO_PORTRAIT: {
        eventId: 'dr.step.photo.portrait',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    DR_STEP_DOCUMENTNUMBER: {
        eventId: 'dr.step.documentnumber',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    DR_STEP_DATA: {
        eventId: 'dr.step.data',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    VC_STEP_TAN: {
        eventId: 'vc.step.tan',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    VC_STEP_ENDING: {
        eventId: 'vc.step.ending',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    SF_RATING: {
        eventId: 'sf.rating',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    SF_SUCCESS: {
        eventId: 'sf.success',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    SF_REDIRECT: {
        eventId: 'sf.redirect',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    SF_IDENT_CANCELLED: {
        eventId: 'sf.cancelled',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    UNEXPECTED_ERROR: {
        eventId: 'unexpected.error',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.ERROR
    },
    SF_LEAVE_BROWSER_WARNING: {
        eventId: 'sf.leave.browser.warning',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    SF_LEAVE_RETRY: {
        eventId: 'sf.leave.retry',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.INFO
    },
    MR_CASE_CHECK_RESULT: {
        eventId: 'mr.case.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_BROWSER_CHECK_RESULT: {
        eventId: 'mr.browser.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_CAM_CHECK_RESULT: {
        eventId: 'mr.cam.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_MIC_CHECK_RESULT: {
        eventId: 'mr.mic.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_CAM_PERMISSION_RESULT: {
        eventId: 'mr.cam.permission.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_CAM_RESOLUTION_CHANGE: {
        eventId: 'mr.cam.resolution.change',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_SPEAKER_CHECK_RESULT: {
        eventId: 'mr.speaker.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_SPEAKER_AUDIO_CHECK_RESULT: {
        eventId: 'mr.speaker.audio.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_MIC_PERMISSION_CHECK_RESULT: {
        eventId: 'mr.mic.permission.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    CR_OCR_CHECK_RESULT: {
        eventId: 'cr.ocr.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    CR_USA_MODULES_CHECK_RESULT: {
        eventId: 'cr.usa.modules.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    CR_USA_CHECK_RESULT: {
        eventId: 'cr.usa.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    MR_SERVICECENTER_CHECK_RESULT: {
        eventId: 'mr.servicecenter.check.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    VC_TAN_CHECK: {
        eventId: 'vc.tan.check',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    DC_SEND_CONTACT_DATA: {
        eventId: 'dc.send.contact.data',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    MR_VIDEOIDENT_CREATED: {
        eventId: 'mr.case.started',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    CR_OCR_PHOTO_CREATION: {
        eventId: 'cr.ocr.photo_creation',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    CR_USA_SEND_DATA: {
        eventId: 'cr.usa.send_data',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    MR_CAMERA_SWITCH: {
        eventId: 'mr.camera_switch',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    MR_FLASHLIGHT_TOGGLE: {
        eventId: 'mr.flashlight_toggle',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    VC_ID: {
        eventId: 'vc.id',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_INIT: {
        eventId: 'vc.init',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_CONNECT: {
        eventId: 'vc.connect',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_DISCONNECT: {
        eventId: 'vc.disconnect',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_WEBRTC_CONNECT: {
        eventId: 'vc.webrtc.connect',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_WEBRTC_DISCONNECT: {
        eventId: 'vc.webrtc.disconnect',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
        logLevel: LOG_LEVELS.WARN
    },
    VC_AGENT_NAME: {
        eventId: 'vc.agent.name',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_REDIRECT_TOKEN: {
        eventId: 'vc.redirect.token',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_WAITING_TIME: {
        eventId: 'vc.waiting.time',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_WEBSOCKET_CONNECT: {
        eventId: 'vc.websocket.connect',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_WEBSOCKET_ERROR: {
        eventId: 'vc.websocket.error',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_POLL_ERROR: {
        eventId: 'vc.poll.error',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_AGENT_MESSAGE: {
        eventId: 'vc.agent.message',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_USER_MESSAGE: {
        eventId: 'vc.user.message',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_TAN_TRANSMITTED: {
        eventId: 'vc.tan.transmitted',
        eventType: EVENT_TYPES.AGENT_MESSAGE,
    },
    VC_WEBRTC_ERROR: {
        eventId: 'vc.webrtc',
        eventType: EVENT_TYPES.WEBRTC,
    },
    VC_LOCAL_STREAM_MUTING: {
        eventId: 'vc.local.stream.muting',
        eventType: EVENT_TYPES.MISC,
    },
    VC_LOCAL_STREAM_MISSING: {
        eventId: 'vc.local.stream.missing',
        eventType: EVENT_TYPES.MISC,
    },
    PH_IMAGE_QUALITY: {
        eventId: 'ph.image.quality',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    PD_START_VIDEO: {
        eventId: 'pd.start-video',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    PD_START_PHOTO: {
        eventId: 'pd.start-photo',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    PD_START_BASIC: {
        eventId: 'pd.start-basic',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    PD_START_EID: {
        eventId: 'pd.start-eid',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    PD_START_AUTOID: {
        eventId: 'pd.start-autoid',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    TAKE_PHOTO_ERROR: {
        eventId: 'take.photo.error',
        eventType: EVENT_TYPES.MISC,
    },
    PHOTO_QUALITY: {
        eventId: 'photo.quality',
        eventType: EVENT_TYPES.MISC,
    },
    REQUEST_ERROR: {
        eventId: 'request.error',
        eventType: EVENT_TYPES.MISC,
    },
    MISC_ERROR: {
        eventId: 'misc.error',
        eventType: EVENT_TYPES.MISC,
    },
    JB_DETECTION_RESULT: {
        eventId: 'jb.detection.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    VC_LAUNCH_ID: {
        eventId: 'vc.launch.id',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    EI_WAITING_FOR_CARD: {
        eventId: 'ei.contact.waiting',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_CONNECTING_CARD: {
        eventId: 'ei.connect.card',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_DISPLAY_DATA: {
        eventId: 'ei.display.data',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_PIN_ENTRY: {
        eventId: 'ei.pin.entry',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_PIN_CHECK: {
        eventId: 'ei.pin.check',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    EI_PIN_RESULT: {
        eventId: 'ei.pin.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    EI_SUCCESS: {
        eventId: 'ei.success',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_ERROR_SCREEN: {
        eventId: 'ei.error.screen',
        eventType: EVENT_TYPES.PAGE_VIEW,
        logLevel: LOG_LEVELS.ERROR
    },
    EI_CERTIFICATE_INFO: {
        eventId: 'ei.certificate.info',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_TRANSPORT_PIN_INFO: {
        eventId: 'ei.transport.pin.info',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_DISPLAY_CHANGE_PIN: {
        eventId: 'ei.display.change.pin',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    EI_CHANGE_PIN_CLICKED: {
        eventId: 'ei.change.pin.clicked',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    EI_CHANGE_PIN_RESULT: {
        eventId: 'ei.change.pin.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    VC_LANGUAGE_SELECTION: {
        eventId: 'vc.language.selection',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CE_QR_SCAN: {
        eventId: 'ce.qr.scan',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    CE_INFO_CASE_ID: {
        eventId: 'ce.info.case-id',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CE_INFO_SETTINGS: {
        eventId: 'ce.info.settings',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    CE_FORCE_UPDATE: {
        eventId: 'ce.force.update',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    BA_COUPON_EMAIL: {
        eventId: 'ba.coupon.email',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    BA_COUPON_DOWNLOAD: {
        eventId: 'ba.coupon.download',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    BA_COUPON_DOWNLOAD_RESULT: {
        eventId: 'ba.coupon.download.result',
        eventType: EVENT_TYPES.CHECK_RESULT,
    },
    BA_DISPLAY_COUPON: {
        eventId: 'ba.display.coupon',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    BA_DISPLAY_FINDER: {
        eventId: 'ba.display.finder',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    BA_APP_INFO: {
        eventId: 'ba.app.info',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    DISPLAY_RATING: {
        eventId: 'display.rating',
        eventType: EVENT_TYPES.PAGE_VIEW,
    },
    PH_UPLOAD_DOCUMENTS: {
        eventId: 'ph.upload.documents',
        eventType: EVENT_TYPES.USER_ACTION,
    },
    TEXT_MISSING: {
        eventId: 'ac.texts.missing',
        eventType: EVENT_TYPES.MISC,
    }
}
