import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Slider from '../misc/Slider'
import './ContinueInBrowser.scss'
import {acceptEidIdentTerms, createEidAttempt, createEidIdent} from '../../lib/emmiApi'
import Text from "../i18n/Text";
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n";
import I18nContext from "../i18n/I18nContext";

export default class ContinueInBrowser extends Component {

    static contextType = I18nContext

    state = {
        hasError: false,
        errorMessage: undefined
    }


    createAttempt = (event) => {
        event.stopPropagation()
        const caseId = this.props.caseId
        createEidIdent(caseId).then(_ => {
            return acceptEidIdentTerms(caseId).then(_ => {
                return createEidAttempt(caseId).then(response => {
                    const attemptId = response.attemptId
                    window.location.href =
                        `http://127.0.0.1:24727/eID-Client?tcTokenURL=` +
                        process.env.REACT_APP_IDENT_PORTAL +
                        `EID/SamlRequest.html?parameter=${caseId}%26attemptId=${attemptId}`;
                })
            });
        }).catch(reason => {
            this.setState(() => ({hasError: true, errorMessage: reason}))
        })
    }


    render() {
        if (this.state.hasError) {
            throw new Error(this.state.errorMessage)
        }

        if(this.props.isMobileDevice || this.props.signingWorkFlow || this.props.v1bWorkflow) {
            return null
        } else {

            return <Slider duration={100}>
                <div className="text-center text-small mb-5" data-test-id="05880657-0c78">
                    <Text k="startEId.browser.preLink"/>
                    <button type="button"
                            className="link-button" data-test-id="a2b760a8-935a" onClick={this.createAttempt}>
                        <Text k="startEId.browser.link"/>
                    </button>
                    &nbsp;
                    <span id="externalLink-AusweisApp"
                        dangerouslySetInnerHTML={{__html: getI18nTextWithContext(this.context, 'startEId.browser.postLink')}}/>
                </div>


            </Slider>
        }
    }
}

ContinueInBrowser.propTypes = {
    caseId: PropTypes.string.isRequired,
    signingWorkFlow: PropTypes.bool,
    v1bWorkflow: PropTypes.bool
}