import React from 'react'
import PropTypes from 'prop-types'
import Select from './Select'
import EmailInput from './EmailInput'

export default function FormGroup({data, onChange, enteredUserData = {}}) {
    const props = {
        name:  data.key,
        id: 'dc-' + data.key,
        maxLength: data.maxLength,
        className: 'form-control ' + (enteredUserData.validated ? (!!enteredUserData.errorText ? 'is-invalid' : 'is-valid') : ''),
        'data-test-id': 'f6504b3a-0705-' + data.key,
        onChange
    }

    return <div className="form-group">
        <label htmlFor={props.id} data-test-id={'698596bc-2be2' + data.key}>
            {data.display} {data.mandatory || <small>(optional)</small>}
        </label>
        { getInput(data.type, props, data.choices) }
        <div className="invalid-feedback">
            {enteredUserData.errorText}
        </div>
    </div>
}

FormGroup.propTypes = {
    data: PropTypes.shape({
        key: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
        mandatory: PropTypes.bool,
        maxLength: PropTypes.number,
        sortKey: PropTypes.number.isRequired,
        choices: PropTypes.array
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    enteredUserData: PropTypes.shape({
        validated: PropTypes.bool,
        validationMessage: PropTypes.string
    })
}

function getInput(fieldType, props, choices) {
    switch (fieldType) {
        case 'email':
            return <EmailInput {...props} />
        case 'choice':
            return <Select props={props} choices={choices} />
        default:
            return <input type="text" {...props} onChange={e => props.onChange(e.target.value)} />
    }
}