import React from "react"
import UnexpectedError from "./UnexpectedError"
import {LOG_EVENTS} from "wipi-common/src/lib/logging/events"
import LOG from "wipi-common/src/lib/logging/logger";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {error: null, errorInfo: null, eventId: null}
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })

        const message=error && error.message
        const info=errorInfo && errorInfo.componentStack && errorInfo.componentStack.trim()
        LOG.logWarn(LOG_EVENTS.UNEXPECTED_ERROR+" | "+message+" | "+info)
    }

    render() {
        if (this.state.errorInfo) {
            return <UnexpectedError/>
        }
        return this.props.children
    }
}

export default ErrorBoundary