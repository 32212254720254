import React from 'react'
import PropTypes from 'prop-types'

export default function FooterLink({target, text, dataTestId}) {
    return <li className="navbar-item">
        <a href={target} target="_blank" rel="noopener noreferrer" className="navbar-link" data-test-id={dataTestId}>{text}</a>
    </li>
}

FooterLink.propTypes = {
    target: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    dataTestId: PropTypes.string
}