import React from 'react'
import PropTypes from 'prop-types'
import FooterLink from './FooterLink'
import DynamicLinks from './DynamicLinks'
import Text from "../i18n/Text";
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n";
import {useI18nContext} from "../i18n/I18nContext";

export default function Footer({legalInfo}) {
    const context = useI18nContext()

    return <>
        <footer id="footer" className="dpag-footer footer mt-5 mb-5">
            <div className="container border-top">
                <p className="mt-3 mb-0">
                    &copy; {(new Date()).getFullYear()} Deutsche Post AG
                </p>
                <nav className="navbar navbar-expand pt-1 pl-0 pr-0">
                    <ul className="navbar-nav">
                        <FooterLink target={getI18nTextWithContext(context, 'imprint_url')}
                                    text={<Text k="footer.imprint"/>}
                                    dataTestId="60a6ce18-745e" />
                        <DynamicLinks legalInfo={legalInfo}/>
                        <FooterLink target={getI18nTextWithContext(context, 'legalnotes_url')}
                                    text={<Text k="footer.legalNotes"/>}
                                    dataTestId="26674d13-88b6" />
                    </ul>
                </nav>
            </div>
        </footer>
    </>
}

Footer.propTypes = {
    legalInfo: PropTypes.object,
    language: PropTypes.string
}