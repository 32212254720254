import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PrincipalDisplayLogo from './PrincipalDisplayLogo'
import postIdentLogo from '../../assets/icons/postident_logo.svg'
import './Header.scss'
import LanguageSelector from "../i18n/LanguageSelector";
import {getSupportedLanguageList} from "wipi-common/src/lib/i18n";

export class Header extends Component {

    chooseLanguage = (element) => {
        this.props.changeLanguage(element)
    }

    render() {
        return <>
            <header id="header" className="headless">
                {!this.props.whitelabel &&
                <div className="dpag-header">
                    <div className="container container-div">
                        <div className="dpag-logo navbar-brand p-0">
                            <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 viewBox="0 0 93 90">
                                <switch>
                                    <g>
                                        <defs>
                                            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="48" x2="48"
                                                            y2="87">
                                                <stop offset="0" stopColor="#FFF4D8"></stop>
                                                <stop offset=".08" stopColor="#FFE580"></stop>
                                                <stop offset=".282" stopColor="#FFD942"></stop>
                                                <stop offset=".461" stopColor="#FFD012"></stop>
                                                <stop offset=".55" stopColor="#FC0"></stop>
                                            </linearGradient>
                                        </defs>
                                        <path className="logo-gradient" fill="url(#SVGID_1_)" d="M90 87H5V0h85"></path>
                                        <path className="logo-lasche" fillRule="evenodd" clipRule="evenodd"
                                              fill="#baa038" d="M5 0L0 11h5"></path>
                                        <path d="M29 57h7l-7 8h-8m18-8h8l-8 8h-8m25-8h-8l8 8h8m2-8h-8l8 8h8"></path>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M61.286 35.886C62.603 32.3 63.52 26.84 63.71 23.02l13.49 3.35c-1.078
                                              15.806-12.953 29.65-30.57 29.643-19.93-.007-27.633-17.303-26.596-30.41l5.854-1.858c-.952
                                              7.458 1.36 12.838 2.956 15.303-1.898-10.55 6.08-19.093 16.067-19.1 8.984-.006 16.414 6.543
                                               16.378 15.938zm-27.145.332c0-5.952 4.836-10.776 10.797-10.776 5.964 0 10.796 4.825 10.796
                                               10.776S50.9 46.994 44.936 46.994c-5.96 0-10.794-4.825-10.794-10.776z">
                                        </path>
                                        <path className="logo-shadow" fill="#baa038" d="M90 11v76H8v3h85V11"></path>
                                    </g>
                                    <foreignObject height="93" width="90">
                                        <span className="logo-fallback"></span>
                                    </foreignObject>
                                </switch>
                            </svg>
                        </div>
                        {!this.props.signingWorkflow &&
                            <div className="dpag-language-div dpag-language">
                                <LanguageSelector languageList={getSupportedLanguageList()}
                                                  changeLanguage={this.chooseLanguage}
                                                  selectedLanguage={this.props.selectedLanguage}/>
                            </div>
                        }
                    </div>
                </div>
                }
                <div className="dpag-subheader">
                    <nav className="navbar p-1 border-bottom">
                        <div className="container">
                            <div className="dpag-product-logo">
                                <img src={postIdentLogo} height="20" alt="POSTIDENT" data-test-id='e00c1de5'/>
                            </div>
                            <div className="dpag-principal-logo">
                                <PrincipalDisplayLogo src={this.props.principalDisplayLogo}
                                                      alt={this.props.principalDisplayName}/>
                            </div>
                            {this.props.whitelabel && !this.props.signingWorkflow &&
                            <div className="dpag-language">
                                <LanguageSelector languageList={getSupportedLanguageList()}
                                                  changeLanguage={this.chooseLanguage}
                                                  selectedLanguage={this.props.selectedLanguage}/>
                            </div>
                            }
                        </div>
                    </nav>
                </div>
            </header>
        </>
    }
}

Header.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }).isRequired,
    signingWorkflow: PropTypes.bool,
    whitelabel: PropTypes.bool,
    principalDisplayLogo: PropTypes.string,
    principalDisplayName: PropTypes.string

}