import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import CaseGoneError from './CaseGoneError'
import ax from 'wipi-common/src/lib/client'
import {
    extendedValidateStatus,
} from 'wipi-common/src/lib/util'
import RateLimitExceededError from './RateLimitExceededError'
import LOG from "wipi-common/src/lib/logging/logger"
import CaseConflictError from "./CaseConflictError";
import CaseCancelledError from "./CaseCancelledError";

const STATUS_CANCELLED = 423
const STATUS_PRECONDITION_REQUIRED = 428
const STATUS_FORBIDDEN = 403
const STATUS_CONFLICT = 409
const STATUS_GONE = 410
const STATUS_TOO_MANY_REQUEST = 429

export function getCaseInfo(caseId) {
    const config = {
        url: `/cases/${caseId}`,
        params: {
            ignoreCurrentMethod: true
        },
        validateStatus: function (status) {
            return extendedValidateStatus(status, [STATUS_PRECONDITION_REQUIRED])
        }
    }
    return ax.request(config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            const response = error.response
            if(isStatusCancelled(response.status)){
                throw new CaseCancelledError(error.response.data.errorText)
            }else if (isStatusGone(response.status)) {
                throw new CaseGoneError()
            }else if (isStatusConflict(response.status)) {
                throw new CaseConflictError()
            } else if (isStatusTooManyRequest(response.status)) {
                throw new RateLimitExceededError()
            } else {
                throw new Error('case info retrieval: ' + caseId)
            }
        })
}

export function getEidIdent(caseId,) {
    const config = {
        url: '/cases/' + caseId + '/eidIdent',
        validateStatus: function (status) {
            return extendedValidateStatus(status, [STATUS_GONE, STATUS_PRECONDITION_REQUIRED])
        }
    }

    return ax.request(config)
        .then(response => {
            if (isStatusOkOrPreconditionRequired(response.status)) {
                return response.data
            }
            throw new Error('unexpected status code')
        })
        .catch(error => {
            LOG.logError(LOG_EVENTS.REQUEST_ERROR, 'Could not retrieve info from server')
            throw error
        })
}

export function createEidIdent(caseId) {
    return ax.request({url: `/cases/${caseId}/eidIdent`})
}

export function acceptEidIdentTerms(caseId) {
    const config = {
        url: `/cases/${caseId}/eidIdent/terms`,
        method: 'PUT',
        data: {accepted: true}
    }
    return ax.request(config);
}

export function createEidAttempt(caseId) {
    const config = {
        url: `/cases/${caseId}/eidIdent/attempts`,
        method: 'POST'
    }

    return ax.request(config).then(response => {
        return response.data
    })
}


export function doEasyConnect(accountingNumber, referenceId) {
    const config = {
        url: `/cases`,
        method: 'POST',
        data: {accountingNumber, referenceId},
        validateStatus: function (status) {
            return extendedValidateStatus(status, [STATUS_PRECONDITION_REQUIRED])
        }
    }

    return ax.request(config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            const response = error.response
            if (isStatusGone(response.status)) {
                throw new CaseGoneError()
            } else if (isStatusConflict(response.status)) {
                if (hasInvalidRef(response.data)) {
                    LOG.logError(LOG_EVENTS.MR_CASE_CHECK_RESULT, 'ReferenceId not unique')
                    throw new CaseConflictError()
                } else {
                    throw new Error('easy connect: ' + accountingNumber)
                }
            } else if (isStatusTooManyRequest(response.status)) {
                throw new RateLimitExceededError()
            } else {
                LOG.logError(LOG_EVENTS.MR_CASE_CHECK_RESULT, 'Could not perform easy connect')
                throw new Error('easy connect: ' + accountingNumber)
            }
        })
}

export function postUserStart(caseId) {
    const config = {
        url: '/cases/' + caseId + '/userStarts',
        method: 'POST'
    }

    return ax.request(config)
        .catch(error => {
            LOG.logError(LOG_EVENTS.REQUEST_ERROR, error.message + ' - ' + caseId)
            throw error
        })
}

export function putContactData(caseId, payload) {
    const config = {
        url: '/cases/' + caseId + '/contactData',
        method: 'PUT',
        data: payload
    }

    return ax.request(config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            if (error.response.status === 400) {
                return new Promise((resolve, reject) => reject(''))
            } else {
                let message = error.message ? error.message : 'Could not put contact data'
                LOG.logError(LOG_EVENTS.MR_CASE_CHECK_RESULT, message)
                throw new Error(message)
            }
        })
}

export function doBrowserCheck(intent, caseId) {
    let intentParam
    switch (intent) {
        case 'video':
        default:
            intentParam = 'VIDEOIDENT'
    }

    let path = '/browsers/permission?intent=' + intentParam
    if (caseId) {
        path += '&caseId=' + caseId
    }

    const config = {
        url: path,
        validateStatus: function (status) {
            return extendedValidateStatus(status, [STATUS_FORBIDDEN])
        }
    }

    return ax.request(config)
        .then(response => {
            let logMessage
            if (isStatusOk(response.status)) {
                logMessage = 'Browser is supported'
            } else if (isStatusForbidden(response.status)) {
                logMessage = 'Browser is not supported'
            } else {
                throw new Error('unexpected status code')
            }
            LOG.logSuccess(LOG_EVENTS.MR_BROWSER_CHECK_RESULT, logMessage)
            return response.data
        }).then(json => ({
            isBrowserSupported: json.deniedIntents && json.deniedIntents.indexOf(intentParam) < 0,
            isMobileDevice: json.deviceType === 'MOBILE'
        }))
        .catch(error => {
            throw error
        })
}

export function getServiceCenterInfo(category) {
    return ax.request({params: {category}, url: 'service-center/info'})
        .then(response => {
            const context = {"selectedCategory": category}
            LOG.logInfo(LOG_EVENTS.CE_VIDEO_METHOD, undefined, undefined, context)
            return response.data
        })
        .catch(error => {
            LOG.logError(LOG_EVENTS.MR_SERVICECENTER_CHECK_RESULT, 'Could not retrieve info from server')
            throw error
        })
}

export function createBasicIdent(caseId) {
    return ax.request({url: '/cases/' + caseId + '/basicIdent'})
        .then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        })
}

export function patchUserMethodSelection(caseId, method) {
    const config = {
        url: '/cases/' + caseId + '/methodSelection',
        method: 'PATCH',
        data: {identificationMethod: method}}

    return ax.request(config)
        .catch(error => {
            LOG.logError(LOG_EVENTS.CE_SELECT_METHOD, 'Unable to patch selected method')
            throw error
        })
}

export function getCouponPdf(caseId) {
    return ax.request({url: '/cases/' + caseId + '/basicIdent/coupon/pdf'})
        .then(response => {
            LOG.logSuccess(LOG_EVENTS.BA_COUPON_DOWNLOAD_RESULT)
            return response.data
        })
        .catch(error => {
            LOG.logError(LOG_EVENTS.BA_COUPON_DOWNLOAD_RESULT, 'Could not retrieve info from server')
            throw error
        })
}

export function sendCouponEmail(caseId) {
    const config = {
        url: '/cases/' + caseId + '/basicIdent/coupon/email',
        method: 'PUT'
    }

    return ax.request(config).catch(error => {
        throw error
    })
}

function isStatusOkOrPreconditionRequired(status) {
    return isStatusOk(status) || status === STATUS_PRECONDITION_REQUIRED
}

function isStatusOk(status) {
    return (status >= 200 && status < 300)
}

function isStatusGone(status) {
    return status === STATUS_GONE
}

function isStatusForbidden(status) {
    return status === STATUS_FORBIDDEN
}

function isStatusTooManyRequest(status) {
    return status === STATUS_TOO_MANY_REQUEST
}

function isStatusConflict(status) {
    return status === STATUS_CONFLICT
}

function isStatusCancelled(status){
    return status === STATUS_CANCELLED
}

function hasInvalidRef(error) {
    return error && error.errorType === "ERROR" && error.errorText === "{referenceId not unique}"
}