import React from 'react'
import verifiedIcon from '../../assets/icons/verified.svg'
import Text from "../i18n/Text";

export default function CaseGoneInfo() {
    return <div className="text-center">
        <img src={verifiedIcon} alt="" className="img-fluid m-4 mw-100" style={{width: '72px'}} data-test-id="0df48425-8be9" />
        <h4 data-test-id="2ed6b9dc-1496"><Text k='common.thank'/></h4>
        <p data-test-id="2654a2e1-f332">
            <Text k="case.gone" />
        </p>
    </div>
}