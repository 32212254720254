import PropTypes from 'prop-types'
import React from 'react'

export default function AutoIdTitle({heading, subHeading, info, testId}) {
    return <div className="mb-4 col-lg-6">
        <h2 className="h4" data-test-id={testId + '-head'}>{heading}</h2>
        { subHeading && <p data-test-id={testId + '-sub'}>{subHeading}</p> }
        <div dangerouslySetInnerHTML={{ __html: info }} />
    </div>
}

AutoIdTitle.propTypes = {
    heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    subHeading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    info: PropTypes.string,
    testId: PropTypes.string.isRequired
}