import React from 'react'
import PropTypes from 'prop-types'
import BackButton from '../misc/BackButton'
import paperPlane from '../../assets/illustrations/paperplane.svg'
import Text from "../i18n/Text";

export default function StartBasicMailConfirmed({isMobileDevice, backToBranchUrl, continueToBranchBtn}) {
    return <>
        <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                <p className="text-center mt-3">
                    <img src={paperPlane} alt="Papierflieger" className="img-fluid" width="140" data-test-id="08ab785a-ecf3" />
                </p>
                <p className="text-center mt-5 mb-5" data-test-id="de03a01f-b5b1">
                    <strong><Text k="startBasic.mailSent" /></strong>
                </p>
            </div>
        </div>
        <hr />
        <section className="btn-section">
            <BackButton isMobileDevice={isMobileDevice} to="/basic"/>
            {backToBranchUrl &&
            <button className="pi-btn pi-btn--primary" onClick={() => {
                window.location.replace(backToBranchUrl)
            }}>
                {continueToBranchBtn}
            </button>
            }
        </section>
    </>
}

StartBasicMailConfirmed.propTypes = {
    isMobileDevice: PropTypes.bool.isRequired,
    backToBranchUrl: PropTypes.string,
    continueToBranchBtn: PropTypes.string
}
