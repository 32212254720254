import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import {CopyableCaseId} from './CopyableCaseId'
import './AppDownload.scss'
import {DEFAULT_LANGUAGE, getI18nTextWithContext} from "wipi-common/src/lib/i18n"
import {useI18nContext} from "../i18n/I18nContext"
import Text from "../i18n/Text"
import {getAppStoreByLanguage, getPlayStoreByLanguage} from "wipi-common/src/components/LanguageImageSort";

const PLAY_STORE_URL = process.env.REACT_APP_APP_PLAY_STORE
const APP_STORE_URL = process.env.REACT_APP_APP_APPLE_STORE
const APP_DEEPLINK_BASE = process.env.REACT_APP_APP_DEEPLINK_BASE

export default function AppDownload({caseId}) {
    const context = useI18nContext()
    const [language, setLanguage] = useState(DEFAULT_LANGUAGE)

    useEffect(() => {
        setLanguage(context.lang)
    }, [context])

    return <>
        <h3 className="h6 mb-2 slim">
            <strong data-test-id="791f97c0-32e8"><Text k="common.preDownloadApp"/></strong>
            <Text k="common.downloadApp"/>
        </h3>
        <div className="apps-download">
            <a href={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
                <img src={getPlayStoreByLanguage(context.lang)}
                     alt={getI18nTextWithContext(context, 'common.piInPlayStore')}
                     height="42"
                     data-test-id="49febda5-1be7" className="margin-right"/>
            </a>
            <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
                <img data-test-id="186773fe-e023"
                     className="pr-1"
                     src={getAppStoreByLanguage(context.lang)}
                     alt={getI18nTextWithContext(context, 'common.piInAppStore')}
                     height="42"/>
            </a>
        </div>
        <h3 className="h6 mt-4 mb-2 slim qr">
            <strong data-test-id="14644b41-1e1c"><Text k="common.preScanQr"/></strong>
            <Text k="common.scanQr"/>
        </h3>
        <div className="border border-dark rounded p-3">
            <div className="row align-items-center">
                <div className="col-auto">
                    <QRCode value={APP_DEEPLINK_BASE + caseId + '&lang=' + language} size={100}/>
                </div>
                <div className="col-auto pl-2 pr-4">
                    <strong data-test-id="d2dd9cf9-7a6f"><Text k="common.caseId"/>:</strong><br/>
                    <CopyableCaseId caseId={caseId}/>
                </div>
            </div>
        </div>
    </>
}

AppDownload.propTypes = {
    caseId: PropTypes.string.isRequired,
    offerIOS: PropTypes.bool
}