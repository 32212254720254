// app showcase eID:
import appShowcaseEid_de from "../assets/img/eid/app-showcase-eid_de.png";
import appShowcaseEid_en from "../assets/img/eid/app-showcase-eid_en.png";
import appShowcaseEid_es from "../assets/img/eid/app-showcase-eid_es.png";
import appShowcaseEid_fr from "../assets/img/eid/app-showcase-eid_fr.png";
import appShowcaseEid_it from "../assets/img/eid/app-showcase-eid_it.png";
import appShowcaseEid_pl from "../assets/img/eid/app-showcase-eid_pl.png";
import appShowcaseEid_pt from "../assets/img/eid/app-showcase-eid_pt.png";
import appShowcaseEid_tr from "../assets/img/eid/app-showcase-eid_tr.png";
// app showcase AutoID:
import appShowcaseAutoId_de from "../assets/img/autoid/app-showcase_autoid_de.png"
import appShowcaseAutoId_en from "../assets/img/autoid/app-showcase_autoid_en.png"
import appShowcaseAutoId_es from "../assets/img/autoid/app-showcase_autoid_es.png"
import appShowcaseAutoId_fr from "../assets/img/autoid/app-showcase_autoid_fr.png"
import appShowcaseAutoId_it from "../assets/img/autoid/app-showcase_autoid_it.png"
import appShowcaseAutoId_pl from "../assets/img/autoid/app-showcase_autoid_pl.png"
import appShowcaseAutoId_pt from "../assets/img/autoid/app-showcase_autoid_pt.png"
import appShowcaseAutoId_tr from "../assets/img/autoid/app-showcase_autoid_tr.png"
// app showcase Video call:
import appShowcaseVideo_de from "../assets/img/video/app-showcase_video_de.png"
import appShowcaseVideo_en from "../assets/img/video/app-showcase_video_en.png"
import appShowcaseVideo_es from "../assets/img/video/app-showcase_video_es.png"
import appShowcaseVideo_fr from "../assets/img/video/app-showcase_video_fr.png"
import appShowcaseVideo_it from "../assets/img/video/app-showcase_video_it.png"
import appShowcaseVideo_pl from "../assets/img/video/app-showcase_video_pl.png"
import appShowcaseVideo_pt from "../assets/img/video/app-showcase_video_pt.png"
import appShowcaseVideo_tr from "../assets/img/video/app-showcase_video_tr.png"
// availability clock
import availabilityClock_de from "../assets/img/availability/available_clock-de.png"
import availabilityClock_en from "../assets/img/availability/available_clock-en.png"
import availabilityClock_es from "../assets/img/availability/available_clock-es.png"
import availabilityClock_fr from "../assets/img/availability/available_clock-fr.png"
import availabilityClock_it from "../assets/img/availability/available_clock-it.png"
import availabilityClock_pl from "../assets/img/availability/available_clock-pl.png"
import availabilityClock_pt from "../assets/img/availability/available_clock-pt.png"
import availabilityClock_tr from "../assets/img/availability/available_clock-tr.png"
// app store iOS:
import appStoreBadge_de from "../assets/icons/app-store/app-store-badge_de.svg"
import appStoreBadge_en from "../assets/icons/app-store/app-store-badge_en.svg"
import appStoreBadge_es from "../assets/icons/app-store/app-store-badge_es.svg"
import appStoreBadge_fr from "../assets/icons/app-store/app-store-badge_fr.svg"
import appStoreBadge_it from "../assets/icons/app-store/app-store-badge_it.svg"
import appStoreBadge_pl from "../assets/icons/app-store/app-store-badge_pl.svg"
import appStoreBadge_pt from "../assets/icons/app-store/app-store-badge_pt.svg"
import appStoreBadge_tr from "../assets/icons/app-store/app-store-badge_tr.svg"
// play store Android:
import googlePlayBadge_de from "../assets/icons/play-store/google-play-badge_de.svg"
import googlePlayBadge_en from "../assets/icons/play-store/google-play-badge_en.svg"
import googlePlayBadge_es from "../assets/icons/play-store/google-play-badge_es.svg"
import googlePlayBadge_fr from "../assets/icons/play-store/google-play-badge_fr.svg"
import googlePlayBadge_it from "../assets/icons/play-store/google-play-badge_it.svg"
import googlePlayBadge_pl from "../assets/icons/play-store/google-play-badge_pl.svg"
import googlePlayBadge_pt from "../assets/icons/play-store/google-play-badge_pt.svg"
import googlePlayBadge_tr from "../assets/icons/play-store/google-play-badge_tr.svg"


const LANGUAGE = {
    DE: "de",
    EN: "en",
    ES: "es",
    FR: "fr",
    IT: "it",
    PL: "pl",
    PT: "pt",
    TR: "tr",
}

export function getEidShowcaseByLanguage(language) {
    return getImageByLanguage(language,
                        appShowcaseEid_de,
                        appShowcaseEid_en,
                        appShowcaseEid_es,
                        appShowcaseEid_fr,
                        appShowcaseEid_it,
                        appShowcaseEid_pl,
                        appShowcaseEid_pt,
                        appShowcaseEid_tr)
}

export function getAutoIdShowcaseByLanguage(language) {
    return getImageByLanguage(language,
                        appShowcaseAutoId_de,
                        appShowcaseAutoId_en,
                        appShowcaseAutoId_es,
                        appShowcaseAutoId_fr,
                        appShowcaseAutoId_it,
                        appShowcaseAutoId_pl,
                        appShowcaseAutoId_pt,
                        appShowcaseAutoId_tr)
    }


export function getVideoShowcaseByLanguage(language) {
    return getImageByLanguage(language,
                        appShowcaseVideo_de,
                        appShowcaseVideo_en,
                        appShowcaseVideo_es,
                        appShowcaseVideo_fr,
                        appShowcaseVideo_it,
                        appShowcaseVideo_pl,
                        appShowcaseVideo_pt,
                        appShowcaseVideo_tr)
    }

export function getAvailabilityClockByLanguage(language) {
    return getImageByLanguage(language,
                        availabilityClock_de,
                        availabilityClock_en,
                        availabilityClock_es,
                        availabilityClock_fr,
                        availabilityClock_it,
                        availabilityClock_pl,
                        availabilityClock_pt,
                        availabilityClock_tr)
    }

export function getAppStoreByLanguage(language) {
    return getImageByLanguage(language,
                        appStoreBadge_de,
                        appStoreBadge_en,
                        appStoreBadge_es,
                        appStoreBadge_fr,
                        appStoreBadge_it,
                        appStoreBadge_pl,
                        appStoreBadge_pt,
                        appStoreBadge_tr)
}

export function getPlayStoreByLanguage(language) {
    return getImageByLanguage(language,
                        googlePlayBadge_de,
                        googlePlayBadge_en,
                        googlePlayBadge_es,
                        googlePlayBadge_fr,
                        googlePlayBadge_it,
                        googlePlayBadge_pl,
                        googlePlayBadge_pt,
                        googlePlayBadge_tr)
}

function getImageByLanguage(language, deImage, enImage, esImage, frImage, itImage, plImage, ptImage, trImage) {
    switch(language) {
        case LANGUAGE.DE:
            return deImage
        case LANGUAGE.EN:
            return enImage
        case LANGUAGE.ES:
            return esImage
        case LANGUAGE.FR:
            return frImage
        case LANGUAGE.IT:
            return itImage
        case LANGUAGE.PL:
            return plImage
        case LANGUAGE.PT:
            return ptImage
        case LANGUAGE.TR:
            return trImage
        default:
            return enImage
    }
}
