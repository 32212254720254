import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import FAQ from '../faq/FAQ'
import {getI18nTextWithContext, getI18nTextWithParentKey} from "wipi-common/src/lib/i18n";
import Title from './EidTitle'
import InstallAppBanner from '../misc/InstallAppBanner'
import ContinueInBrowser from './ContinueInBrowser'
import ShortCaseInfo from '../misc/ShortCaseInfo'
import BackToMethodSelectionButton from '../misc/BackToMethodSelectionButton'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import './StartEid.scss'
import Text from "../i18n/Text";
import LOG from "wipi-common/src/lib/logging/logger"
import {useI18nContext} from "../i18n/I18nContext"
import {getAvailabilityClockByLanguage, getEidShowcaseByLanguage} from "wipi-common/src/components/LanguageImageSort";

const START_EID_DOCUMENT_TITLE = 'POSTIDENT | '

export default function StartEId({caseInfo, isMobileDevice}) {
    const context = useI18nContext()

    // Temporary solution to hide the continue button if eid ident has a DIU phase
    let v1bWorkflow = caseInfo.modules.identMethodSelection.methodChoice.find(method => {
        return method.method === 'eid'
    }).forceUpdate;

    useEffect(() => {
        const title = getI18nTextWithContext(context, 'startEId.title')
        document.title = START_EID_DOCUMENT_TITLE + title
        LOG.logInfo(LOG_EVENTS.PD_START_EID)
    }, [context])

    return <div className="col-lg-12" id="eid-page">
        <div className="headless">
            <div className="row margin-left">
                <Title heading={<Text k="startEId.heading"/>}
                       subHeading={<Text k="startEId.subHeading"/>}
                   PRNG    info={getI18nTextWithContext(context, 'startEId.info')}
                       testId="32cba09d-513b"/>
                <div className="col-lg-4 clock-wrapper">
                    <img className="clock" src={getAvailabilityClockByLanguage(context.lang)}
                         alt={getI18nTextWithContext(context, 'startEId.accessibility')}/>
                </div>
            </div>
            <InstallAppBanner heading=" "
                              showcaseImg={getEidShowcaseByLanguage(context.lang)}
                              caseId={caseInfo.caseId}
                              isMobileDevice={isMobileDevice}
                              method="eid"
            />
            <ContinueInBrowser caseId={caseInfo.caseId} isMobileDevice={isMobileDevice} signingWorkFlow={caseInfo.signingWorkFlow} v1bWorkflow={v1bWorkflow}/>
            <hr/>
            <BackToMethodSelectionButton caseInfo={caseInfo} isMobileDevice={isMobileDevice}/>
            <ShortCaseInfo caseId={caseInfo.caseId} bcName={caseInfo.principalDisplayName}/>
        </div>
        <FAQ faq={getI18nTextWithParentKey(context, 'startEId.faq')}/>
    </div>
}

StartEId.propTypes = {
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired
    }).isRequired,
    isMobileDevice: PropTypes.bool.isRequired
}