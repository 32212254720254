import React from 'react'

export default function MainContainer({children}) {
    return <>
        <main id="main-content" className="mt-5">
            <div className="container mt-4 mb-5">
                { children }
            </div>
        </main>
    </>
}