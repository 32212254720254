import {LOG_LEVELS} from './events'

const EVENT_STATUS = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
}
const BUFFER_SEND_SIZE = 10

class Logger {
    constructor(getServerTime, postLog, getMetaInfoState) {
        if (!getServerTime || !postLog || !getMetaInfoState) {
            throw new Error('missing args to construct Logger')
        }
        this.getServerTime = getServerTime
        this.postLog = postLog
        this.getMetaInfoState = getMetaInfoState

        this.serverTimeOffset = undefined
        this.pendingServerTimeRequest = null
        this.logBuffer = []
    }

    log(identMethod, {eventId, eventType, logLevel = LOG_LEVELS.INFO}, message, eventStatus) {
        return this.sendLog(identMethod, eventId, eventType, message, eventStatus, logLevel)
    }

    logSuccess(identMethod, {eventId, eventType}, message, eventStatus = EVENT_STATUS.SUCCESS) {
        return this.sendLog(identMethod, eventId, eventType, message, eventStatus, LOG_LEVELS.INFO)
    }

    logInfo(identMethod, {eventId, eventType}, message, eventStatus,eventContext) {
        return this.sendLog(identMethod, eventId, eventType, message, eventStatus, LOG_LEVELS.INFO,eventContext)
    }

    logWarn(identMethod, {eventId, eventType}, message, eventStatus = EVENT_STATUS.ERROR,eventContext) {
        return this.sendLog(identMethod, eventId, eventType, message, eventStatus, LOG_LEVELS.WARN,eventContext)
    }

    logError(identMethod, {eventId, eventType}, message, eventStatus = EVENT_STATUS.ERROR) {
        return this.sendLog(identMethod, eventId, eventType, message, eventStatus, LOG_LEVELS.ERROR)
    }

    flushLog() {
        if (this.logBuffer.length > 0) {
            this.postLog(this.logBuffer)
            this.logBuffer = []
        }
    }

    sendLog(identMethod, eventId, eventType, message, eventStatus, logLevel,eventContext) {
        return this.getServerTimeOffset().then(() => {
            const logEvent = this.getMetaInfo(identMethod, logLevel)
            logEvent.message = {
                eventId, eventType, message, eventStatus,eventContext
            }
            this.logBuffer.push(logEvent)
            if (logEvent.logLevel === LOG_LEVELS.ERROR || logEvent.logLevel === LOG_LEVELS.WARN || this.logBuffer.length === BUFFER_SEND_SIZE) {
                this.flushLog()
            }
        })
    }

    getMetaInfo(identMethod, logLevel) {
        const state = this.getMetaInfoState()
        let logEvent = {
            timestamp: Date.now() + this.serverTimeOffset,
            logLevel
        }
        if (identMethod) {
            logEvent.identMethod = identMethod
        }
        if (state.sessionId) {
            logEvent.sessionId = state.sessionId
        }
        if (state.caseId) {
            logEvent.caseId = state.caseId
        }
        if (state.chatId) {
            logEvent.nmChatId = '' + state.chatId
        }
        if (state.attemptId) {
            logEvent.attemptId = state.attemptId
        }
        return logEvent
    }

    getServerTimeOffset() {
        if (this.pendingServerTimeRequest) {
            return this.pendingServerTimeRequest
        } else if (this.serverTimeOffset === undefined) {
            this.pendingServerTimeRequest = this.calculateServerTimeOffset()
            return this.pendingServerTimeRequest.then(offset => {
                this.pendingServerTimeRequest = null
                this.serverTimeOffset = offset
                return offset
            })
        } else {
            return Promise.resolve(this.serverTimeOffset)
        }
    }

    calculateServerTimeOffset() {
        const clientTime = Date.now()
        return this.getServerTime().then(time => {
            const currentClientTime = Date.now()
            const serverResponseTime = time.serverTime
            const timeOffsetTheta = serverResponseTime - clientTime
            const roundTripDelayDelta = (currentClientTime - clientTime) / 2
            const offset = Math.round(timeOffsetTheta - roundTripDelayDelta)
            if (!isNaN(offset)) {
                return offset
            } else {
                return 0
            }
        }).catch(() => 0)
    }
}

export default Logger