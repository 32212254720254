import React from 'react'
import PropTypes from 'prop-types'
import ContinueInApp from './ContinueInApp'
import AppDownload from './AppDownload'
import "./InstallAppBanner.scss"

export default function InstallAppBanner({heading, showcaseImg, caseId, isMobileDevice, method}) {
    const colClass = (method && method === 'eid') ? "col-md-3" : "col-auto"
    return <>
        {heading && <p className="text-center mb-5">{heading}</p>}
        <div className="row justify-content-center mb-3 showcase-section">
            <div className={colClass}>
                <div>
                    <img className="img-fluid mb-5 mr-md-5" src={showcaseImg} alt="POSTIDENT App"
                         data-test-id="09ea9966-711b"/>
                </div>
            </div>
            <div className="col-auto app-section">
                {isMobileDevice ? <ContinueInApp caseId={caseId}/> : <AppDownload caseId={caseId}/>}
            </div>
        </div>
    </>
}

InstallAppBanner.propTypes = {
    heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    caseId: PropTypes.string.isRequired,
    isMobileDevice: PropTypes.bool.isRequired,
    method: PropTypes.string,
}