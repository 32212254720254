import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import FAQ from '../faq/FAQ'
import {getI18nTextWithContext, getI18nTextWithParentKey} from "wipi-common/src/lib/i18n";
import {getParameterValue} from "wipi-common/src/lib/util";
import Spinner from "../misc/Spinner";
import {useI18nContext} from "../i18n/I18nContext";

export default function StartPhoto({caseInfo}) {
    const context = useI18nContext()

    useEffect(() => {
        const title = getI18nTextWithContext(context, 'startPhoto.title')
        document.title = 'POSTIDENT | ' + title
    }, [context])

    if (getParameterValue('headless')) {
        return <div className="col-md-12" data-test-id="f1204b3a-2201">
            <FAQ faq={getI18nTextWithParentKey(context, 'startPhoto.faq')}/>
        </div>
    }
    let url = '/identportal/?vorgangsnummer=' + caseInfo.caseId + '&verfahren=photo'
    window.location.replace(url)
    return <a href={url}><Spinner/></a>
}

StartPhoto.propTypes = {
    caseInfo: PropTypes.shape({
        caseId: PropTypes.string.isRequired
    }).isRequired
}

