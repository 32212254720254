import {getParameterByName} from "./util";
import client from './client'
import LOG from "./logging/logger";
import {LOG_EVENTS} from "./logging/events";

const languages = {}
export const DEFAULT_LANGUAGE = {value: 'de', title: 'Deutsch'}
const ENGLISH_LANGUAGE = {value: 'en', title: 'English'}
const SPANISH_LANGUAGE = {value: 'es', title: 'Español'}
const POLISH_LANGUAGE = {value: 'pl', title: 'Polski'}
const ITALIAN_LANGUAGE = {value: 'it', title: 'Italiano'}
const PORTOGUESE_LANGUAGE = {value: 'pt', title: 'Português'}
const FRENCH_LANGUAGE = {value: 'fr', title: 'Français'}
const TURKISH_LANGUAGE = {value: 'tr', title: 'Türkçe'}

export const LANGUAGE_SOURCE = {
    SIGNING: 'SIGNING', BROWSER: 'BROWSER', STORAGE: 'STORAGE', QUERY_STRING: 'QUERY_STRING', DEFAULT: 'DEFAULT'
}


export function loadAppConfig(language) {
    let resource = languages[language]
    if (resource && resource.length > 0) {
        return Promise.resolve(resource)
    }

    const config = {
        url: `/app-config?t=` + new Date().getTime()
    }

    return client.request(config)
        .then(response => {
            languages[language] = response.data.texts
            return Promise.resolve({
                maxAllowedUploadSize: response.data.maxAllowedUploadSize,
                texts: languages[language]
            })
        })
        .catch(reason => {
            LOG.logError(LOG_EVENTS.REQUEST_ERROR, 'Could not load lang ' + reason)
            return Promise.reject(reason)
        })
}

export function isResourceBundleKey(message) {
    return (typeof message === 'string') && /\w+\.\w+/.test(message) && !/\w+\s\w+/g.test(message)
}

export function getI18nTextWithParentKey(context, parentKey) {
    if (context && context.ready) {
        const resource = languages[context.lang]
        return Object.keys(resource)
            .filter((item) => item.indexOf(parentKey) >= 0)
    }
    return []
}

export function getI18nTextWithContext(context, keyPath, fallback) {
    if (context && context.ready) {
        return getI18nText(context.lang, keyPath, fallback)
    }
    return fallback ? fallback : keyPath
}

export function getI18nText(language, keyPath, fallback) {
    const resource = languages[language]
    fallback = fallback ? fallback : ''

    if (!resource || !keyPath || keyPath.length === 0) {
        return fallback
    }
    let text = languages[language][keyPath]
    if (text)
        return text

    LOG.logWarn(LOG_EVENTS.TEXT_MISSING, undefined, undefined, {"key": keyPath})
    return fallback
}

export function determineDefaultLanguage() {
    let source = LANGUAGE_SOURCE.DEFAULT
    const signingWorkflow = localStorage.getItem("signingWorkflow")
    const localValue = localStorage.getItem("language")
    const queryParams = getParameterByName("lang")
    const browserLanguage = getBrowserLanguage()

    let eligible = DEFAULT_LANGUAGE.value
    if (signingWorkflow && signingWorkflow === 'true') {
        source = LANGUAGE_SOURCE.SIGNING
    } else if (queryParams) {
        eligible = queryParams
        source = LANGUAGE_SOURCE.QUERY_STRING
    } else if (localValue) {
        eligible = localValue
        source = LANGUAGE_SOURCE.STORAGE
    } else if (browserLanguage) {
        eligible = browserLanguage
        source = LANGUAGE_SOURCE.BROWSER
    }

    let selectedLanguage = eligible
    if (!getLanguageByCode(eligible)) {
        selectedLanguage = DEFAULT_LANGUAGE.value
    }

    return Promise.resolve({
        "selectionSource": source,
        "deviceLanguage": browserLanguage,
        "eligibleLanguage": eligible,
        "selectedLanguage": selectedLanguage,
        "wasLanguageChanged": false
    })
}

export function getBrowserLanguage() {
    return window.navigator.language && window.navigator.language.slice(0, 2)
}

export function getSupportedLanguageList() {
    return [
        DEFAULT_LANGUAGE,
        ENGLISH_LANGUAGE,
        SPANISH_LANGUAGE,
        POLISH_LANGUAGE,
        ITALIAN_LANGUAGE,
        PORTOGUESE_LANGUAGE,
        FRENCH_LANGUAGE,
        TURKISH_LANGUAGE
    ];
}

export function getLanguageByCode(code) {
    return getSupportedLanguageList()
        .find(i => i.value === code)
}