import Logger from 'wipi-common/src/lib/logging/'
import {parseCaseIdFromUrl} from "../util";
import {getServerTime, postLog} from "../client";

function getMetaInfoState() {
    let caseId = parseCaseIdFromUrl()
    return () => ({caseId})
}

const LOGGER = new Logger(getServerTime, postLog, getMetaInfoState())
export default {
    logInfo: (...args) => {
        args.unshift(null)
        LOGGER.logInfo.apply(LOGGER, args)
            .then(() => LOGGER.flushLog.apply(LOGGER)) // always flush due to the low number of events and short stay of the user on page
    },
    logWarn: LOGGER.logWarn.bind(LOGGER, null),
    logError: LOGGER.logError.bind(LOGGER, null),
    logSuccess: (...args) => {
        args.unshift(null)
        LOGGER.logSuccess.apply(LOGGER, args)
            .then(() => LOGGER.flushLog.apply(LOGGER)) // always flush due to the low number of events and short stay of the user on page
    }
}